<template>
  <div class="testDiv">
    <h1>Test Page</h1>
    <vue-tel-input
      :autoFormat="false"
      :inputOptions="inputOptions"
      :dropdownOptions="dropdownOptions"
      ref="telInput"
    ></vue-tel-input>
    <button @click="check">check</button>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

import ImgItem from "../components/imgItem.vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
export default {
  name: "Test",
  data() {
    return {
      sceneList: [
        {
          id: 0,
          url: "/scene/Livingroom02.jpg",
          isSelect: false,
        },
        {
          id: 1,
          url: "/scene/Livingroom01.jpg",
          isSelect: false,
        },
        {
          id: 2,
          url: "/scene/Bedroom.jpg",
          isSelect: false,
        },
        {
          id: 3,
          url: "/scene/Diningroom.jpg",
          isSelect: false,
        },
        {
          id: 4,
          url: "/scene/Cloakroom.jpg",
          isSelect: false,
        },
      ],
      autoFormat: false,
      inputOptions: {
        placeholder: "",
        styleClasses:"telInput",
        autocomplete: false,
      },
      dropdownOptions: {
        showFlags:true,
        showDialCodeInList: false,
        showDialCodeInSelection: true,
      },
    };
  },
  components: {
    Carousel,
    Slide,
    ImgItem,
    VueTelInput,
  },
  computed: {},
  methods: {
    check() {
      console.log(this.$refs.telInput.phoneObject);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
div.testContainer {
  width: 500px;
}

.styleScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.styleScroll::-webkit-scrollbar {
  width: 10px;
}

.styleScroll::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
</style>
