<template>
  <div :class="['imgItem', { select: isSelect }]">
    <a @click="onSelect">
      <img
        v-show="isSelect"
        class="selectPattern"
        src="@/assets/img/pattern.png" />
      <slot name="img"></slot
    ></a>
    <transition>
      <p class="selectText centerWrapper" v-show="isLike">{{ $t("Select") }}</p>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ImgItem",
  props: {
    id: {
      default: "",
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    isLike: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {
    onSelect() {
      this.$emit("select", this.id);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "../assets/scss/imgItem";
</style>
