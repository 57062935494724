<template>
  <div class="materialSelectDiv">
    <div class="header">
      <div class="searchDiv">
        <input
          type="text"
          class="baseInput search"
          v-model="searchText"
          :placeholder="$t('Search')"
        />
        <img class="YcenterWrapper" src="@/assets/img/btnSearch.png" />
      </div>
      <div class="filterDiv">
        <span class="already" v-if="isSetFilter">{{ $t("AlreadyFilter") }}</span>
        <span v-else>{{ $t("Filter") }}</span>
        <a @click="isShowFilter = !isShowFilter">
          <img src="@/assets/img/btnFilter.png" />
        </a>
      </div>
    </div>
    <div class="pageSelect XcenterWrapperRel">
      <div :class="['page', { select: pageSelect == 'cabinet' }]">
        <a @click="onSelectPage('cabinet')">
          <span
            :class="{ select: pageSelect == 'cabinet' }"
            class="pageTag"
          >{{ $t("page.Cabinet") }}</span>
        </a>
      </div>
      <div :class="['page', { select: pageSelect == 'wall' }]">
        <a @click="onSelectPage('wall')">
          <span :class="{ select: pageSelect == 'wall' }" class="pageTag">{{ $t("page.Wall") }}</span>
        </a>
      </div>
      <div :class="['page', { select: pageSelect == 'floor' }]">
        <a @click="onSelectPage('floor')">
          <span :class="{ select: pageSelect == 'floor' }" class="pageTag">{{ $t("page.Floor") }}</span>
        </a>
      </div>
    </div>
    <div class="materialDiv XcenterWrapperRel styleScroll">
      <template v-for="(item, index) in getMaterialList">
        <div class="materialItem" :key="item.id">
          <ImgItem
            class="material"
            @select="onSelect"
            :id="item.id"
            :isSelect="item.isSelect"
            :isLike="item.isLike"
          >
            <template v-slot:img>
              <img class="photo" v-lazy="item.url" />
            </template>
          </ImgItem>
          <Heart :id="index" :isCheck.sync="item.isLike" class="heartIcon"></Heart>
          <p>{{ item.id }}</p>
          <p>{{ item.name }}</p>
        </div>
      </template>
    </div>
    <div class="materialDivMobile XcenterWrapperRel">
      <carousel
        class="materialCarousel"
        :perPage="3"
        :loop="true"
        :navigationEnabled="true"
        :paginationEnabled="false"
        :navigationNextLabel="getNextButton"
        :navigationPrevLabel="getPrevButton"
        :scrollPerPage="true"
        :touchDrag="false"
        @page-change="onChangeSliderPage"
        ref="slider"
      >
        <slide v-for="(item, index) in getMaterialList" :key="index">
          <div v-show="!item.isEmpty" class="materialItem">
            <ImgItem
              class="material"
              @select="onSelect"
              :id="item.id"
              :isSelect="item.isSelect"
              :isLike="item.isLike"
            >
              <template v-slot:img>
                <img class="photo" v-lazy="item.url" />
              </template>
            </ImgItem>
            <Heart :id="index" :isCheck.sync="item.isLike" class="heartIcon"></Heart>
            <p>{{ item.id }}</p>
            <p class="name">{{ item.name }}</p>
          </div>
        </slide>
      </carousel>
      <div class="sliderPageText">
        <p>{{ getPageText }}</p>
      </div>
    </div>
    <div
      v-show="isShowFilter"
      :class="[
        { filterPlaneDiv: !isMobileDevice },
        { filterPlaneDivMobile: isMobileDevice },
      ]"
    >
      <div class="filterContent styleScroll">
        <p>{{ $t("ProductType") }}</p>
        <div class="typeList">
          <template v-for="(item, index) in filterList[pageSelect]['product']">
            <div :key="index" :class="[{ select: isTypeSelect(item.id) }, 'typeItem']">
              <a v-if="getLang == 'zh'" @click="onTypeFilter(item.id)">
                <span>{{ item.name }}</span>
              </a>
              <a v-else-if="getLang == 'ch'" @click="onTypeFilter(item.id)">
                <span>{{ item.nameCH }}</span>
              </a>
              <a v-else @click="onTypeFilter(item.id)">
                <span>{{ item.nameEN }}</span>
              </a>
            </div>
          </template>
        </div>
        <p>{{ $t("WoodType") }}</p>
        <div class="woodList">
          <template v-for="(item, index) in filterList[pageSelect]['wood']">
            <div :key="index" :class="[{ select: isWoodSelect(item.id) }, 'woodItem']" v-if="getMaterialFilterList['woodType'].includes(item.id)">
              <a v-if="getLang == 'zh'" @click="onWoodFilter(item.id)">
                <span>{{ item.name }}</span>
              </a>
              <a v-else-if="getLang == 'ch'" @click="onWoodFilter(item.id)">
                <span>{{ item.nameCH }}</span>
              </a>
              <a v-else @click="onWoodFilter(item.id)">
                <span>{{ item.nameEN }}</span>
              </a>
            </div>
          </template>
        </div>
        <p>{{ $t("ColorType") }}</p>
        <div class="colorList">
          <template v-for="(item, index) in filterList[pageSelect]['color']">
            <a @click="onColorFilter(item.id)" :key="index" v-if="getMaterialFilterList['colorType'].includes(item.id)">
              <div :class="[{ select: isColorSelect(item.id) }, 'colorItem']">
                <div :class="'fillColor0' + item.id"></div>
              </div>
            </a>
          </template>
        </div>
      </div>
      <div class="filterAction">
        <button class="btn btnYellow hover-opacity" @click="clearFilter">
          <span>{{ $t("ClearFilter") }}</span>
        </button>
        <button class="btn btnYellow hover-opacity" @click="onCloseFilter">
          <span>{{ $t("Submit") }}</span>
        </button>
      </div>
      <a @click="onCloseFilter">
        <img class="btnClose" src="@/assets/img/btnClose.png" />
      </a>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { isMobile } from "mobile-device-detect";
import axios from "axios";
import { Carousel, Slide } from "vue-carousel";
import ImgItem from "../components/imgItem.vue";
import Heart from "../components/heart.vue";
export default {
  name: "MaterialSelect",
  data() {
    return {
      pageSelect: "cabinet",
      materialList: {},
      defaultMaterial: {},
      likeSet: {},
      selectSet: {},
      isShowFilter: false,
      searchText: "",
      typeFilter: [],
      woodTypeFilter: [],
      colorFilter: [],
      nowSliderPage: 0,
    };
  },
  props: {
    selectScene: {
      type: Number,
      default: 0
    },
    filterList: {
      type: Object,
      default: function () {
        return {
          cabinet: { product: [], wood: [], color: [] },
          floor: { product: [], wood: [], color: [] },
          wall: { product: [], wood: [], color: [] },
        };
      },
    },
  },
  components: { Carousel, Slide, ImgItem, Heart },
  computed: {
    isMobileDevice() {
      return this.$store.state.isMobile;
    },
    isSetFilter: function () {
      return (
        this.typeFilter.length != 0 ||
        this.woodTypeFilter.length != 0 ||
        this.colorFilter.length != 0
      );
    },
    getMaterialList: function () {

      var filterList = [];
      if (this.isSetFilter) {
        for (let i = 0; i < this.materialList[this.pageSelect].length; i++) {
          if (this.typeFilter.length > 0) {
            //Type
            let type = this.materialList[this.pageSelect][i].type;
            if (this.typeFilter.indexOf(type) == -1) {
              continue;
            }
          }

          if (this.woodTypeFilter.length > 0) {
            //Wood Type
            let woodType = this.materialList[this.pageSelect][i].woodType;
            if (this.woodTypeFilter.indexOf(woodType) == -1) {
              continue;
            }
          }

          if (this.colorFilter.length > 0) {
            //Color
            let color = this.materialList[this.pageSelect][i].color;
            if (this.colorFilter.indexOf(color) == -1) {
              continue;
            }
          }

          filterList.push(this.materialList[this.pageSelect][i]);
        }
      } else {
        filterList = this.materialList[this.pageSelect]
      }

      var afterKeyword = [];
      if (this.searchText != "") {
        for (let i = 0; i < filterList.length; i++) {
          var name = "";
          switch (this.$i18n.locale) {
            case "zh": {
              name = filterList[i].name;
              break;
            }
            case "ch": {
              name = filterList[i].nameCH;
              break;
            }
            case "en": {
              name = filterList[i].nameEN;
              break;
            }
          }

          if (
            name.toUpperCase().indexOf(this.searchText.toUpperCase()) != -1 ||
            filterList[i].id.toUpperCase().indexOf(this.searchText.toUpperCase()) != -1
          ) {
            afterKeyword.push(filterList[i]);
          }
        }
      } else {
        afterKeyword = filterList;
      }
      var self = this
      let topIndex = -1
      switch (this.pageSelect) {
        case 'cabinet': { //Cabinet

          topIndex = afterKeyword.findIndex(item =>
            item.dbid == self.defaultMaterial.idCabinet
          )
          break;
        }
        case 'wall': { //Wall
          topIndex = afterKeyword.findIndex(item =>
            item.dbid == self.defaultMaterial.idWall
          )
          break;
        }
        case 'floor': { //Floor
          topIndex = afterKeyword.findIndex(item =>
            item.dbid == self.defaultMaterial.idFloor
          )
          break;
        }
      }
      if (topIndex != -1) {
        let topItem = afterKeyword[topIndex]
        afterKeyword.splice(topIndex, 1)
        afterKeyword.unshift(topItem)
      }

      return afterKeyword;
    },

    getMaterialFilterList: function () {
      const list = this.getMaterialList
      // 過濾重複的類別
      const woodTypeSet = new Set(), colorTypeSet = new Set()
      list.forEach(({woodType, color}) => {
        if(!woodTypeSet.has(woodType)){
          woodTypeSet.add(woodType)
        }
        if(!colorTypeSet.has(color)){
          colorTypeSet.add(color)
        }
      })

      const woodType = Array.from(woodTypeSet),
        colorType = Array.from(colorTypeSet)

      return {woodType, colorType}
    },

    getNextButton() {
      return "<img src='./assets/arrowRight.svg' class='arrow'>";
    },
    getPrevButton() {
      return "<img src='./assets/arrowLeft.svg' class='arrow'>";
    },
    getLang() {
      return this.$i18n.locale;
    },
    getPageText() {
      let itemNum = this.getMaterialList.length
      let text = (this.nowSliderPage + 1) + "/" + Math.ceil(itemNum / 3.0)
      return text
    }


  },
  methods: {
    onSelectPage(val) {
      this.pageSelect = val;
      if (this.isMobileDevice) {
        this.$refs["slider"].goToPage(0, "pagination");
      }
      this.clearFilter();
      this.scrollToTop();
    },
    onSelect(id) {
      var index = -1;
      for (var i = 0; i < this.materialList[this.pageSelect].length; i++) {
        this.materialList[this.pageSelect][i].isSelect = false;
        if (this.materialList[this.pageSelect][i].id == id) {
          index = i;
        }
      }
      const logApiUrl = this.$store.state.kdAPIUrl + "/api/event/material"
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const data = {
        type: this.pageSelect,
        serial: id,
        add: 0,
        login_id: this.$store.state.loginData.id
      }
      axios.post(logApiUrl, JSON.stringify(data), config).then(({data}) => {
        console.log(`log material click ${this.pageSelect}`)
      })

      this.materialList[this.pageSelect][index].isSelect =
        !this.materialList[this.pageSelect][index].isSelect;
      this.$emit(
        "selectMaterial",
        {
          type: this.pageSelect,
          material: this.materialList[this.pageSelect][index]
        }
      );
    },
    onLikeCheck(val, isLike) {
      this.materialList[this.pageSelect][val].isLike = isLike;

      this.$emit(
        "setLikeSet",
        {
          materialType: this.pageSelect,
          material: this.materialList[this.pageSelect][val]
        }
      );
    },
    onTypeFilter(typeID) {
      var itemID = this.typeFilter.indexOf(typeID);
      if (itemID == -1) {
        this.typeFilter.push(typeID);
      } else {
        this.typeFilter.splice(itemID, 1);
      }
    },
    isTypeSelect(typeID) {
      return this.typeFilter.indexOf(typeID) != -1;
    },
    onWoodFilter(typeID) {
      var itemID = this.woodTypeFilter.indexOf(typeID);
      if (itemID == -1) {
        this.woodTypeFilter.push(typeID);
      } else {
        this.woodTypeFilter.splice(itemID, 1);
      }
      
    },
    isWoodSelect(typeID) {
      return this.woodTypeFilter.indexOf(typeID) != -1;
    },
    onColorFilter(typeID) {
      var itemID = this.colorFilter.indexOf(typeID);
      if (itemID == -1) {
        this.colorFilter.push(typeID);
      } else {
        this.colorFilter.splice(itemID, 1);
      }
    },
    isColorSelect(typeID) {
      return this.colorFilter.indexOf(typeID) != -1;
    },
    clearFilter() {
      this.colorFilter = [];
      this.woodTypeFilter = [];
      this.typeFilter = [];
    },
    onCloseFilter(){
      this.isShowFilter = false
      this.$refs["slider"].goToPage(0, "pagination");
    },

    changePage(val) {
      if (this.pageSelect != val) {
        this.pageSelect = val;
        this.clearFilter();
        this.scrollToTop();
      }

    },
    onChangeSliderPage(val) {
      if (!isNaN(val)) {
        this.nowSliderPage = val;
      }

    },

    getLikeSet() {
      var newLikeSet = {
        cabinet: [],
        floor: [],
        wall: [],
      };

      for (let i = 0; i < this.materialList["cabinet"].length; i++) {
        if (this.materialList["cabinet"][i].isLike) {
          newLikeSet.cabinet.push(this.materialList["cabinet"][i]);
        }
      }

      for (let i = 0; i < this.materialList["floor"].length; i++) {
        if (this.materialList["floor"][i].isLike) {
          newLikeSet.floor.push(this.materialList["floor"][i]);
        }
      }

      for (let i = 0; i < this.materialList["wall"].length; i++) {
        if (this.materialList["wall"][i].isLike) {
          newLikeSet.wall.push(this.materialList["wall"][i]);
        }
      }
      return newLikeSet;
    },

    scrollToTop() {
      let materialList = document.querySelector(".materialDiv")
      if (materialList) {
        materialList.scrollTop = 0
      }

    },


  },
  created() {
    this.materialList = this.$store.getters.MaterialList()
    this.likeSet = this.$store.getters.LikeMaterial()
    this.selectSet = this.$store.getters.SelectSet()

  },
  mounted() {

    this.defaultMaterial = this.$store.getters.SceneDefaultMaterial(this.selectScene)

    var self = this;
    var tagList = ['cabinet', 'floor', 'wall']

    for (let tagIdx = 0; tagIdx < tagList.length; tagIdx++) {
      let tag = tagList[tagIdx]
      //Reset
      for (let i = 0; i < this.materialList[tag].length; i++) {
        this.materialList[tag][i].isLike = false;
        this.materialList[tag][i].isSelect = false;
      }

      //Set Like
      for (let i = 0; i < this.likeSet[tag].length; i++) {
        let index = this.materialList[tag].findIndex(
          (x) => x.id === this.likeSet[tag][i].id
        );
        if (index != -1) {
          this.materialList[tag][index].isLike = true;
        } else {
          this.materialList[tag][index].isLike = false;
        }
      }
      //Set Select
      let select = this.materialList[tag].find(
        (x) => x.id == this.selectSet[tag].id
      );
      if (select != undefined) {
        select.isSelect = true;
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/materialSelect";
</style>
