<template>
  <div class="propertyList">
    <h1>{{ getTypeName }}</h1>
    <button @click="onAdd()">新增</button>
    <vue-good-table
      :columns="tableColumns"
      :rows="propertyData"
      :search-options="{
        enabled: true,
      }"
      :pagination-options="paginationOptions"
      @on-per-page-change="onPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <button class="editBtn" @click="onEdit(props.row)">編輯</button>
        </span>
        <span v-else-if="props.column.field == 'cabinet'">
          <span v-if="props.row.cabinet == 0">x</span>
          <span v-else>v</span>
        </span>
        <span v-else-if="props.column.field == 'floor'">
          <span v-if="props.row.floor == 0">x</span>
          <span v-else>v</span>
        </span>
        <span v-else-if="props.column.field == 'wall'">
          <span v-if="props.row.wall == 0">x</span>
          <span v-else>v</span>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import axios from "axios";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
export default {
  name: "PropertyList",
  data() {
    return {
      type: "",
      tableColumns: [
        // {
        //   label: "編號",
        //   field: "id",
        // },
        {
          label: "名稱(中)",
          field: "nameZH",
        },
        {
          label: "名稱(英)",
          field: "nameEN",
        },
        {
          label: "地板",
          field: "floor",
        },
        {
          label: "牆面",
          field: "wall",
        },
        {
          label: "櫃體",
          field: "cabinet",
        },
        {
          label: "資料",
          field: "action",
        },
      ],
      propertyData: [],
      paginationOptions: {
        enabled: true,
        mode: "pages",
        perPage: 10,
      },
    };
  },
  watch: {
    $route(to, from) {
      this.initData();
    },
  },
  components: {
    VueGoodTable,
  },
  computed: {
    getTypeName() {
      var name = "";
      switch (this.type) {
        case "color": {
          name = "顏色類別";
          break;
        }
        case "product": {
          name = "產品類別";
          break;
        }
        case "wood": {
          name = "木種類別";
          break;
        }
      }
      return name;
    },
  },
  methods: {
    getBaseUrl() {
      var baseUrl = "";
      switch (this.type) {
        case "color": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/color";
          break;
        }
        case "product": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/product";
          break;
        }
        case "wood": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/wood";
          break;
        }
      }
      return baseUrl;
    },
    initData() {
      this.type = this.$route.params["type"];
      var getDataApi = this.getBaseUrl() + '/sort';
      var self = this;
      axios.get(getDataApi).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.propertyData = rep.data;
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onAdd() {
      var link = {
        name: "PropertyEditor",
        params: {
          type: this.type,
          action: "add",
        },
      };
      this.$router.push(link);
    },
    onEdit(data) {
      this.$store.commit("setEditProperty", data);
      var link = {
        name: "PropertyEditor",
        params: {
          type: this.type,
          action: "edit",
          id: data.id,
        },
      };
      this.$router.push(link);
    },
    onPageChange(params) {
      localStorage.setItem("perpage", params.currentPerPage);
    },
  },
  created() {
    var perpageTemp = localStorage.getItem("perpage");
    if (perpageTemp != null) {
      this.paginationOptions.perPage = parseInt(perpageTemp);
    }
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped lang="scss">
div.propertyList {
  margin-left: 1rem;
  width: 80vw;
}
</style>
