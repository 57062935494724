// beta
// export const API_URL = 'https://mspbeta.twkd.com';
// www tw
// export const API_URL = 'https://msp.twkd.com';
// export const LANG = 'tw';

// 要修改大陸官網要改這邊
// www ch
export const API_URL = 'https://msp.twkd.com.cn';
export const LANG = 'cn';

// https://msp.twkd.com.cn/api/order