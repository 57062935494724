<template>
  <div class="menu">
    <div class="buttonList">
      <img src="@/assets/img/kdLogoL.png" />
      <router-link :to="{ name: 'Orders' }"><span>訂單檢視</span></router-link>
      <router-link :to="{ name: 'PropertyList', params: { type: 'product' } }"
        ><span>產品類別</span></router-link
      >
      <router-link :to="{ name: 'PropertyList', params: { type: 'wood' } }"
        ><span>木種類別</span></router-link
      >
      <!-- <router-link :to="{ name: 'PropertyList', params: { type: 'color' } }"
        ><span>顏色類別</span></router-link
      > -->
      <router-link :to="{ name: 'MaterialList', params: { type: 'floor' } }"
        ><span>地板材質</span></router-link
      >
      <router-link :to="{ name: 'MaterialList', params: { type: 'cabinet' } }"
        ><span>櫃體材質</span></router-link
      >
      <router-link :to="{ name: 'MaterialList', params: { type: 'wall' } }"
        ><span>牆壁材質</span></router-link
      >
      <router-link :to="{ name: 'Default' }"><span>預設材質</span></router-link>

      <router-link :to="{ name: 'Sequence' }"><span>自訂排序</span></router-link>

      <router-link :to="{ name: 'Change' }"><span>修改密碼</span></router-link>
      
      <router-link :to="{ name: 'BrowserTrack' }"><span>網站追蹤</span></router-link>

      <router-link :to="{ name: 'BatchUpload' }"><span>批量上傳</span></router-link>
      
      <a @click="logout()"><span>登出</span></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {
    logout(){
      localStorage.removeItem('isLogin');
      this.$router.push({ name: "Login" });
    }
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/media";
@import "../../assets/scss/color";
@import "../../assets/scss/font";
@import "../../assets/scss/basic";
div.menu {
  position: relative;
  width: 10vw;
  background-color: $Yellow;

  div.buttonList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      display: block;
      color: $Black;
      font-size: 1.5rem;
      margin: 1rem;
    }

    img{
      width:40%;
      padding:2rem 0;
    }
  }
}
</style>
