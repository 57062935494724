<template>
  <div class="listContent styleScroll">
    <p>{{ $t("ItemList") }}</p>
    <div class="itemUnit">
      <span class="name">{{ $t("page.Cabinet") }}</span>
      <div class="itemList">
        <ul>
          <li v-for="(item, index) in likeSet['cabinet']" :key="index">
             {{ item.id + ' ' + item.name }}
            <div v-if="item.similarItem.length > 0 && item.similarItem[0] != ''" class="similarDiv">
              <span>{{ $t("Similar") }}</span>
              <ul class="similarItem">
                <li
                  v-for="(similarItem, similarItemIndex) in item.similarItem"
                  :key="similarItemIndex"
                >{{ similarItem }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="itemUnit">
      <span class="name">{{ $t("page.Wall") }}</span>
      <div class="itemList">
        <ul>
          <li v-for="(item, index) in likeSet['wall']" :key="index">
             {{ item.id + ' ' + item.name }}
            <div v-if="item.similarItem.length > 0 && item.similarItem[0] != ''" class="similarDiv">
              <span>{{ $t("Similar") }}</span>
              <ul class="similarItem">
                <li
                  v-for="(similarItem, similarItemIndex) in item.similarItem"
                  :key="similarItemIndex"
                >{{ similarItem }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="itemUnit">
      <span class="name">{{ $t("page.Floor") }}</span>
      <div class="itemList">
        <ul>
          <li v-for="(item, index) in likeSet['floor']" :key="index">
            {{ item.id + ' ' + item.name }}
            <div v-if="item.similarItem.length > 0 && item.similarItem[0] != ''" class="similarDiv">
              <span>{{ $t("Similar") }}</span>
              <ul class="similarItem">
                <li
                  v-for="(similarItem, similarItemIndex) in item.similarItem"
                  :key="similarItemIndex"
                >{{ similarItem }}</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemList",
  props: {
    likeSet: {
      type: Object,
      default: function () {
        return { cabinet: [], floor: [], wall: [] };
      },
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {},
  mounted() { },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/media";
@import "../assets/scss/color";
@import "../assets/scss/font";
@import "../assets/scss/basic";
div.listContent {
  p {
    margin: 0;
    font-weight: bold;
    @include font-size(24);
    padding-bottom: 1rem;
    border-bottom: 1px solid $Black;
  }

  div.itemUnit {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid $Black;

    span.name {
      @include font-size(21);
      width: px2rem(100);
      text-align: center;

      @include sm {
        width: px2rem(75);
        @include font-size(17);
      }
    }

    div.itemList {
      border-left: 3px solid $Yellow;
      min-height: px2rem(100);
      margin-bottom: 1rem;

      ul {
        margin: 0;
        margin-left: 1rem;

        li {
          font-weight: 300;
          line-height: 150%;
          @include font-size(23);

          @include sm {
            @include font-size(20);
          }
        }
      }

      div.similarDiv {
        display: flex;

        span {
          font-weight: normal;
          margin: 0;
          padding: 0;
          border-bottom: none;
          @include font-size(16);
          @include sm {
            @include font-size(14);
          }
        }

        ul.similarItem {
          list-style-type: none;
          margin-top: 5px;
          margin-left: 0;
          padding-left: 3px;
          li {
            @include font-size(16);

            @include sm {
              @include font-size(14);
            }
          }
        }
      }
    }
  }
}
</style>
