<template>
  <div class="stepBar">
    <img class="logo" src="@/assets/img/kdLogoS.png" />
    <a @click="$emit('changeStep', 'chooseScene')">
      <p :class="[{ select: step == 'chooseScene' }, { en: isEN }]">{{ $t("SelectScene") }}</p>
    </a>
    <img class="arrow" src="@/assets/img/arrow.png" />
    <a @click="$emit('changeStep', 'chooseMetarial')">
      <p :class="[{ select: step == 'chooseMetarial' }, { en: isEN }]">{{ $t("SelectMaterial") }}</p>
    </a>
    <img class="arrow" src="@/assets/img/arrow.png" />
    <a @click="$emit('changeStep', 'checkItemList')">
      <p :class="[{ select: step == 'checkItemList' }, { en: isEN }]">{{ $t("MyList") }}</p>
    </a>
    <img class="arrow" src="@/assets/img/arrow.png" />
    <a @click="$emit('changeStep', 'contact')">
      <p
        :class="[{ select: step == 'contact' || step == 'finish' }, { en: isEN }]"
      >{{ $t("Download") }}</p>
    </a>
  </div>
</template>

<script>
export default {
  name: "StepBar",
  props: {
    step: {
      type: String,
      default: "chooseScene",
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    isEN() {
      return this.$i18n.locale == "en";
    },
  },
  methods: {},
  mounted() { },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/stepBar";
</style>
