<template>
  <div class="materialEdit">
    <h1 v-if="action == 'add'">新增{{ getTypeName }}</h1>
    <h1 v-else>編輯{{ getTypeName }}</h1>

    <div class="formDiv">
      <div>
        <label for="serial">序號</label>
        <input :disabled="action != 'add'" v-model="materialData.serial" type="text" id="serial" />
      </div>
      <div>
        <label for="nameZH">名稱(繁中)</label>
        <input v-model="materialData.nameZH" type="text" id="nameZH" />
      </div>
      <div>
        <label for="nameCH">名稱(簡中)</label>
        <input v-model="materialData.nameCH" type="text" id="nameCH" />
      </div>
      <div>
        <label for="nameEN">名稱(英文)</label>
        <input v-model="materialData.nameEN" type="text" id="nameEN" />
      </div>
      <div>
        <label for="product">產品種類</label>
        <select id="product" v-model="materialData.type">
          <option
            v-for="(item, index) in productList"
            :value="item.id"
            :key="index"
          >
            {{ item.nameZH }}
          </option>
        </select>
      </div>
      <div>
        <label for="wood">木種</label>
        <select id="wood" v-model="materialData.woodType">
          <option
            v-for="(item, index) in woodList"
            :value="item.id"
            :key="index"
          >
            {{ item.nameZH }}
          </option>
        </select>
      </div>
      <div>
        <label for="color">顏色</label>
        <select id="color" v-model="materialData.colorType">
          <option
            v-for="(item, index) in colorList"
            :value="item.id"
            :key="index"
          >
            {{ item.nameZH }}
          </option>
        </select>
      </div>
      <div>
        <label for="similarListZH">相似品項(繁中)</label>
        <textarea
          id="similarListZH"
          v-model="materialData.similarListZH"
        ></textarea>
      </div>
      <div>
        <label for="similarListCH">相似品項(簡中)</label>
        <textarea
          id="similarListCH"
          v-model="materialData.similarListCH"
        ></textarea>
      </div>
      <div>
        <label for="similarListEN">相似品項(英文)</label>
        <textarea
          id="similarListEN"
          v-model="materialData.similarListEN"
        ></textarea>
      </div>
      <div class="uploadDiv">
        <span>材質</span>
        <label>
          <div class="drapDiv" @dragover.prevent @drop="onDrop">
            <img v-if="image != ''" class="photo" :src="image" />
          </div>
          <input type="file" name="image" @change="onChange" />
        </label>
      </div>
      <br />
      <div>
        <input type="checkbox" id="needProcessing" v-model="needProcessing" />
        <label for="needProcessing">立即更新?</label>
      </div>
      <div v-if="isNeedSync">
        <input type="checkbox" id="needSync" v-model="needSync" />
        <label for="needSync">櫃體、牆壁同步更新?</label>
      </div>
    </div>
    <br />
    <div class="action">
      <button @click="onCancal">取消</button>
      <button v-if="action == 'add'" @click="onAdd">新增</button>
      <button v-else @click="onUpdate">更新</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "MaterialEdit",
  data() {
    return {
      type: "",
      action: "",
      targetID: -1,
      materialData: {
        serial: "",
        nameZH: "",
        nameCH: "",
        nameEN: "",
        colorType: 1,
        woodType: 1,
        type: 1,
        similarListZH: "",
        similarListCH: "",
        similarListEN: "",
        materialID: "",
      },
      needProcessing: false,
      needSync: true,
      image: "",
      materialFile: null,
      woodList: [],
      productList: [],
      colorList: [],
    };
  },
  components: {},
  computed: {
    getTypeName() {
      var name = "";
      switch (this.type) {
        case "cabinet": {
          name = "櫃體材質";
          break;
        }
        case "floor": {
          name = "地板材質";
          break;
        }
        case "wall": {
          name = "牆壁材質";
          break;
        }
      }
      return name;
    },
    isNeedSync() {  // 需要同步另外的材質
      return ['cabinet', 'wall'].includes(this.type);
    }
  },
  methods: {
    urltoFile(url, filename, mimeType) {
      return fetch(url)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, { type: mimeType });
        });
    },
    getBaseUrl() {
      var baseUrl = "";
      switch (this.type) {
        case "cabinet": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/cabinet";
          break;
        }
        case "floor": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/floor";
          break;
        }
        case "wall": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/wall";
          break;
        }
      }
      return baseUrl;
    },
    getMaterialFile() {},
    backToList() {
      var link = {
        name: "MaterialList",
        params: {
          type: this.type,
        },
      };
      this.$router.push(link);
    },
    init(type) {
      var self = this;
      //Get Wood
      var getWoodApi = this.$store.state.kdAPIUrl + "/api/wood/list/" + type;
      axios.get(getWoodApi).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.woodList = rep.data;
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });

      //Get Color
      var getColorApi = this.$store.state.kdAPIUrl + "/api/color/list/" + type;
      axios.get(getColorApi).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.colorList = rep.data;
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });

      //Get Product
      var getProductApi =
        this.$store.state.kdAPIUrl + "/api/product/list/" + type;
      axios.get(getProductApi).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.productList = rep.data;
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onCancal() {
      console.log("cancal");
      this.backToList();
    },

    onUpdate() {
      var updateApi = this.getBaseUrl() + "/" + this.targetID;
      var formData = new FormData();

      formData.append("serial", this.materialData.serial);
      formData.append("nameZH", this.materialData.nameZH);
      formData.append("nameCH", this.materialData.nameCH);
      formData.append("nameEN", this.materialData.nameEN);
      formData.append("type", this.materialData.type);
      formData.append("colorType", this.materialData.colorType);
      formData.append("woodType", this.materialData.woodType);
      formData.append("similarListZH", this.materialData.similarListZH);
      formData.append("similarListCH", this.materialData.similarListCH);
      formData.append("similarListEN", this.materialData.similarListEN);
      formData.append("needProcessing", this.needProcessing);
      if(this.isNeedSync){
        formData.append("needSync", this.needSync);
      }
      formData.append("material", this.materialFile);

      var self = this;
      axios.post(updateApi, formData).then((response) => {
        var rep = response.data;
        if (rep.result) {
          alert("更新成功");
          self.backToList();
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onAdd() {
      var uploadApi = this.getBaseUrl();
      var formData = new FormData();

      formData.append("serial", this.materialData.serial);
      formData.append("nameZH", this.materialData.nameZH);
      formData.append("nameCH", this.materialData.nameCH);
      formData.append("nameEN", this.materialData.nameEN);
      formData.append("type", this.materialData.type);
      formData.append("colorType", this.materialData.colorType);
      formData.append("woodType", this.materialData.woodType);
      formData.append("similarListZH", this.materialData.similarListZH);
      formData.append("similarListCH", this.materialData.similarListCH);
      formData.append("similarListEN", this.materialData.similarListEN);
      formData.append("needProcessing", this.needProcessing);
      if(this.isNeedSync){
        formData.append("needSync", this.needSync);
      }
      if (this.materialFile != null) {
        formData.append("material", this.materialFile);
      }

      var self = this;
      axios.post(uploadApi, formData).then((response) => {
        var rep = response.data;
        if (rep.result) {
          alert("新增成功");
          self.backToList();
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onDelete() {
      var api = this.getBaseUrl() + "/" + this.targetID;
      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var self = this;
      axios.delete(api, config).then((response) => {
        var rep = response.data;
        if (rep.result) {
          alert("刪除成功");
          self.backToList();
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
    },
    onChange(e) {
      var files = e.target.files;
      this.createFile(files[0]);
    },
    createFile(file) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      console.log("Create File");
      var self = this;
      var reader = new FileReader();

      var newImg = new Image();
      newImg.crossOrigin = "Anonymous";
      newImg.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        var sw = newImg.naturalWidth;
        var sh = newImg.naturalHeight;
        var size;
        if (sw > sh) {
          size = sh;
        } else {
          size = sw;
        }
        canvas.height = 1024;
        canvas.width = 1024;
        ctx.drawImage(newImg, 0, 0, size, size, 0, 0, 1024, 1024);
        var dataURL = canvas.toDataURL("image/jpeg", 0.8);

        self.image = dataURL;
        self.urltoFile(dataURL, "material.jpg", "image/jpg").then((file) => {
          console.log("createFile Finish");
          self.materialFile = file;
        });
      };

      reader.onload = function (e) {
        newImg.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
  mounted() {
    this.type = this.$route.params["type"];
    this.action = this.$route.params["action"];
    this.init(this.type);
    if (this.action == "edit") {
      this.targetID = this.$route.params["id"];
      this.materialData = this.$store.state.materialData;
      this.image =
        this.$store.state.kdAPIUrl +
        "/storage/" +
        this.type +
        "/" +
        this.materialData.materialID +
        ".jpg";
    }
  },
};
</script>

<style scoped lang="scss">
div.materialEdit {
  margin-left: 1rem;

  div.formDiv {
    div {
      margin: 0.5rem 0;
    }

    input {
      margin-left: 0.5rem;
    }

    select {
      -webkit-appearance: auto;
      margin-left: 0.5rem;
      font-size: 1rem;
    }
    div.uploadDiv {
      text-align: left;

      div.drapDiv {
        border: 2px solid black;
        position: relative;
        width: 200px;
        height: 200px;

        img.photo {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      input[type="file"] {
        position: absolute;
        opacity: 0;
      }
    }
  }
}
</style>
