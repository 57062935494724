import Vue from "vue";
import Vuex from "vuex";
import { API_URL } from '@/config';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  state: {
    isMobile: false,
    chooseScene: 0,
    loginData: {},
    kdAPIUrl: API_URL,
    materialList: {
      cabinet: [],
      floor: [],
      wall: []
    },
    likeList: {
      cabinet: [],
      floor: [],
      wall: []
    },
    selectSet: {
      cabinet: null,
      floor: null,
      wall: null,
    },
    defaultData: [],
    materialData: {},
    propertyData: {}
  },
  mutations: {
    setEditMaterial(state, data) {
      state.materialData = data;
    },
    setIsMobile(state, data) {
      state.isMobile = data;
    },
    setDefaultMaterial(state, data) {
      state.defaultData = data;
      for (let dIndex = 0; dIndex < state.defaultData.length; dIndex++) {
        state.defaultData[dIndex].wallOrderID = 0
        state.defaultData[dIndex].floorOrderID = 0
        state.defaultData[dIndex].cabinetOrderID = 0
      }
    },
    setEditProperty(state, data) {
      state.propertyData = data;
    },

    setDefaultMaterialOrder(state, materialType) {
      if(state.defaultData.length )
      for (let mIndex = 0; mIndex < state.materialList[materialType].length; mIndex++) {
        let dbID = state.materialList[materialType][mIndex].dbid;
        for (let dIndex = 0; dIndex < state.defaultData.length; dIndex++) {

          switch (materialType) {
            case 'wall': {
              if (dbID === state.defaultData[dIndex].idWall) {
                state.defaultData[dIndex].wallOrderID = mIndex
              }
              break;
            }
            case 'floor': {
              if (dbID === state.defaultData[dIndex].idFloor) {
                state.defaultData[dIndex].floorOrderID = mIndex
              }
              break;
            }
            case 'cabinet': {
              if (dbID === state.defaultData[dIndex].idCabinet) {
                state.defaultData[dIndex].cabinetOrderID = mIndex
              }
              break;
            }
          }
        }
      }

    },

    setLoginData(state, data){
      state.loginData = data
    },

    initMaterialList(state, payload) {
      state.materialList[payload.materialType] = payload.data
    },
    changeLangofMaterialList(state, selectLang) {
      let tagList = ["cabinet", "floor", "wall"]

      for (let tagIdx = 0; tagIdx < 3; tagIdx++) {
        let tag = tagList[tagIdx]
        for (let i = 0; i < state.materialList[tag].length; i++) {
          if (selectLang == "zh") {
            state.materialList[tag][i].name =
              state.materialList[tag][i].nameZH;
            state.materialList[tag][i].similarItem =
              state.materialList[tag][i].similarItemZH;
          } else if (selectLang == "ch") {
            state.materialList[tag][i].name =
              state.materialList[tag][i].nameCH;
            state.materialList[tag][i].similarItem =
              state.materialList[tag][i].similarItemCH;
          } else {
            state.materialList[tag][i].name =
              state.materialList[tag][i].nameEN;
            state.materialList[tag][i].similarItem =
              state.materialList[tag][i].similarItemEN;
          }
        }
      }
    },

    resetDefaultSelectAndLikeSet(state, scene) {
      state.selectSet = {
        cabinet: "",
        floor: "",
        wall: "",
      }

      state.likeList = {
        cabinet: [],
        floor: [],
        wall: [],
      }

      if (state.materialList['floor'][state.defaultData[scene].floorOrderID]) {
        state.selectSet.floor = JSON.parse(JSON.stringify(state.materialList['floor'][state.defaultData[scene].floorOrderID]))
      }

      if (state.materialList['cabinet'][state.defaultData[scene].cabinetOrderID]) {
        state.selectSet.cabinet = JSON.parse(JSON.stringify(state.materialList['cabinet'][state.defaultData[scene].cabinetOrderID]))
      }

      if (state.materialList['wall'][state.defaultData[scene].wallOrderID]) {
        state.selectSet.wall = JSON.parse(JSON.stringify(state.materialList['wall'][state.defaultData[scene].wallOrderID]))
      }

      state.chooseScene = scene
    },
    setSelectSet(state, payload) {
      state.selectSet[payload.materialType] = JSON.parse(JSON.stringify(payload.materialData))
    },
    updateLikeSet(state, likeSet) {
      state.likeList = {
        cabinet: [],
        floor: [],
        wall: [],
      }
      state.likeList = JSON.parse(JSON.stringify(likeSet))
    },
    removeLike(state, payload) {
      if (payload.index >= 0 && payload.index < state.likeList[payload.type].length) {
        state.likeList[payload.type].splice(payload.index, 1)
      }
    }
  },
  getters: {
    //Not Cache
    MaterialList: (state) => () => {
      return JSON.parse(JSON.stringify(state.materialList))
    },
    DefaultMaterial(state) {
      return JSON.parse(JSON.stringify(state.defaultData))
    },
    SceneDefaultMaterial: (state) => (scene) => {
      return JSON.parse(JSON.stringify(state.defaultData[scene]))
    },
    LikeMaterial: (state) => () => {
      return JSON.parse(JSON.stringify(state.likeList))
    },
    SelectSet: (state) => () => {
      return JSON.parse(JSON.stringify(state.selectSet))
    },
    FinalList: (state,getters) => (scene) => {
      let finalSet = JSON.parse(JSON.stringify(state.likeList));
      var tagList = ['cabinet', 'floor', 'wall']
      for (let tagIdx = 0; tagIdx < tagList.length; tagIdx++) {
        let tag = tagList[tagIdx]
        // 儲存預設值
        let selectIndex = finalSet[tag]?.findIndex(item => item.id == state.selectSet[tag].id)
        if (selectIndex == -1) {
          let select = JSON.parse(JSON.stringify(state.selectSet[tag]))
          select.isSelect = true
          finalSet[tag].push(select)
        }
      }
      return finalSet;
    },
  },
  actions: {
    changeLangAsync({ commit }, lang) {
      return new Promise((resolve, reject) => {
        commit('changeLangofMaterialList', lang)
        resolve()
      })
    }
  },
  modules: {},
});
