<template>
  <div class="adminContainer">
    <Menu></Menu>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import Menu from "./admin/Menu.vue";
export default {
  name: "AdminContainer",
  data() {
    return {};
  },
  components: {
    Menu,
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
div.adminContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100vw;
  min-height: 100vh;
}
</style>
