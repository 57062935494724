<template>
  <div class="updateAccount">
    <h1>修改密碼</h1>
    <div class="formDiv">
      <div>
        <label for="oldPassword">舊密碼</label>
        <input v-model="oldPassword" type="text" id="oldPassword" />
      </div>
      <div>
        <label for="newPassword">新密碼</label>
        <input v-model="newPassword" type="text" id="newPassword" />
      </div>
      <div>
        <label for="confirm">再輸入一次</label>
        <input v-model="confirm" type="text" id="confirm" />
      </div>
    </div>
    <div class="action">
      <button @click="onUpdate">更新</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UpdateAccount",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirm: "",
    };
  },
  components: {},
  computed: {},
  methods: {
    onUpdate() {
      var nowAccount = localStorage.getItem("isLogin");
      if (nowAccount == null) {
        this.$router.push({ name: "Login" });
      }

      if (this.newPassword.length <= 0 || this.newPassword != this.confirm) {
        alert("輸入密碼錯誤");
      } else {
        var postData = {
          data: {
            account: nowAccount,
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
        };
        var jsonData = JSON.stringify(postData);

        var updateApi = this.$store.state.kdAPIUrl + "/api/admin/change";
        var config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        var self = this;
        axios.put(updateApi, jsonData, config).then((response) => {
          var rep = response.data;
          if (rep.result) {
            alert("更新成功");
          } else {
            alert("更新失敗 Server Error:" + rep.ErrMsg);
          }
        });
      }
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">

div.updateAccount {
  margin-left: 1rem;

  div.formDiv {
    div {
      margin: 0.5rem 0;
    }

    input {
      margin-left: 0.5rem;
    }

    select {
      -webkit-appearance: auto;
      margin-left: 0.5rem;
      font-size: 1rem;
    }
  }
}
</style>
