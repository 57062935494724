var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"finishDiv"},[(!_vm.isMobileDevice)?_c('img',{attrs:{"src":require("@/assets/img/kdLogoF.png")}}):_vm._e(),_c('div',{class:[{ en: _vm.isEN }, 'textBox']},[_c('pre',{class:{ en: _vm.isEN }},[_vm._v(_vm._s(_vm.$t("FinishText")))]),_c('div',{staticClass:"btnBox"},[(_vm.isZH)?_c('div',[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.isCH)?_c('div',[_vm._m(2),_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.isEN)?_c('div',[_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e()])]),(_vm.isMobileDevice)?_c('div',{staticClass:"listPlaneDiv"},[_c('itemList',{staticClass:"itemList",attrs:{"likeSet":_vm.likeSet}})],1):_vm._e(),_c('div',{staticClass:"actionDiv"},[_c('button',{staticClass:"btn btnYellow hover-opacity",on:{"click":_vm.onRetry}},[_c('span',[_vm._v("＜ "+_vm._s(_vm.$t("Retry")))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://line.me/R/ti/p/@bax1723d"}},[_c('img',{attrs:{"src":require("@/assets/img/finish/ZH_LINE.jpg"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/twkeding"}},[_c('img',{attrs:{"src":require("@/assets/img/finish/ZH_FB.jpg"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://www.douyin.com/user/MS4wLjABAAAAJEm-Tqw2NtX4-_vSIHbi5kAi38JXq0GjKJbpmrxHEH_64v1uReKoWobbD4jYNVCV"}},[_c('img',{attrs:{"src":require("@/assets/img/finish/CN_抖音.jpg"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://www.cnkeding.com/data/editor/files/kdwechat.jpg"}},[_c('img',{attrs:{"src":require("@/assets/img/finish/CN_微信.jpg"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://amos.alicdn.com/getcid.aw?spm=a1z10.3-b-s.0.0.605680beWZGCGh&v=3&uid=kd%BF%C6%B6%A8%C6%EC%BD%A2%B5%EA&site=cntaobao&groupid=167810093&s=1&charset=gbk&fromid=cntaobao"}},[_c('img',{attrs:{"src":require("@/assets/img/finish/CN_旺旺.jpg"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"http://www.instagram.com/kd_panels/?hl=zh-tw"}},[_c('img',{attrs:{"src":require("@/assets/img/finish/EN_IG.jpg"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/kd.panel"}},[_c('img',{attrs:{"src":require("@/assets/img/finish/EN_FB.jpg"),"alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"target":"_blank","href":"https://linktr.ee/kdpanels"}},[_c('img',{attrs:{"src":require("@/assets/img/finish/EN_Linktree.jpg"),"alt":"","srcset":""}})])
}]

export { render, staticRenderFns }