<template>
    <div class="browserTrack">
        <div class="lang-select-box">
            <v-select
            v-model="langSelect"
            :options="options"
            :clearable = false
            dense></v-select>
        </div>
        <h1>網站追蹤</h1>
        <ul class="login-list">
            <li>{{$t("track.noRepeatUser")}}<span> {{statistics.loginCount}} </span></li>
            <li>{{$t("track.browseCount")}}<span> {{statistics.loginTimes}} </span></li>
            <li>{{$t("track.noRepeatProcess")}}<span> {{statistics.successCount}} </span></li>
            <li>{{$t("track.processCount")}}<span> {{statistics.successTimes}} </span></li>
        </ul>
        <ul class="table-list">
            <li>
                <h3>{{$t("track.sceneSelect")}}</h3>
                <vue-good-table
                    :columns="sceneColumn"
                    :rows="sceneRow"
                    >
                </vue-good-table>
            </li>
            <li>
                <h3>{{$t("track.materialSelect")}}</h3>
                <vue-good-table
                    :columns="materialColumn"
                    :rows="materialRow"
                    >
                </vue-good-table>
            </li>
            <li>
                <h3>{{$t("track.download")}}</h3>
                <vue-good-table
                    :columns="downloadColumn"
                    :rows="downloadRow">
                </vue-good-table>
            </li>
        </ul>    

    </div>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import vSelect from "vue-select";
// hsuan, 20231213, 語系設定
import { LANG } from '@/config';
const LangZH = { label: "繁體中文", langTag: "zh" };
const LangCH = { label: "简体中文", langTag: "ch" };
const LangEN = { label: "English", langTag: "en" };
export default {
    name:'BrowserTrack',
    components: {
        vSelect,
        VueGoodTable,
    },
    computed: {
        getLocale() {
           return this.$i18n.locale;
        }
    },
    data() {
        return {
            // hsuan, 20231213, 語系設定
            langSelect: (LANG == 'cn')? LangCH : LangZH,
            options: [LangZH, LangCH, LangEN],
            sceneColumn: [],
            sceneRow: [],
            materialColumn: [],
            materialRow: [],
            downloadColumn: [],
            downloadRow: [],
            regions: [],
            statistics: {
                loginTimes: 0,
                loginCount: 0,
                successTimes: 0,
                successCount: 0,
            }
        }
    },

    mounted(){
        // 設定多語系
        // hsuan, 20231213, 語系設定
        if(LANG == 'cn') {
            this.changeLang(LangCH)
        }else{
            this.changeLang(LangZH)
        }        
    },

    watch: {
        langSelect(to, from){
            this.changeLang(to)
        }
    },

    methods: {
        changeLang(lang){
            this.$i18n.locale = lang.langTag;

            this.sceneColumn = [
                {
                    label: this.$t('scene.LivingRoom02'),
                    field: "livingRoomA"
                },
                {
                    label: this.$t('scene.LivingRoom01'),
                    field: "livingRoomB"
                },
                {
                    label: this.$t('scene.Bedroom'),
                    field: "bedroom"
                },
                {
                    label: this.$t('scene.Diningroom'),
                    field: "diningRoom"
                },
                {
                    label: this.$t('scene.Cloakroom'),
                    field: "closetRoom"
                },  
            ]

            this.materialColumn = [
                {
                    label: this.$t('track.materialCol.serial'),
                    field: "serial",
                },
                {
                    label: this.$t('track.materialCol.click'),
                    field: "click",
                },
                {
                    label: this.$t('track.materialCol.add'),
                    field: "add",
                },
            ]

            this.downloadColumn = [
                {
                    label: this.$t('page.Cabinet'),
                    field: "cabinet",
                },
                {
                    label: this.$t('page.Wall'),
                    field: "wall",
                },
                {
                    label: this.$t('page.Floor'),
                    field: "floor",
                },
            ]

            this.fetchData()
        },

        formatSceneRow(data){
            const defaultRow = {
                livingRoomA: 0,
                livingRoomB: 0,
                bedroom: 0,
                diningRoom: 0,
                closetRoom: 0
            }

            for (const key in defaultRow) {
                if(key in data){
                    defaultRow[key] = data[key]
                }
            }
            this.sceneRow = [defaultRow]
        },
        formatMaterialRow(data){
            const tempObj = {}
            data.map(({type, serial, action}) => {
                const key = [type, serial].join()
                const click = action == 'click'? 1: 0

                if(!(key in tempObj))
                    tempObj[key] = {type, serial, add: 0, click: 0}

                if(click){
                    tempObj[key].click++
                }else{
                    tempObj[key].add++
                }
            })
            this.materialRow = Object.values(tempObj)
        },
        formatDownloadRow(data){
            this.downloadRow = []
            data.map(_d => {
                this.downloadRow.push(_d)
            })
        },

        fetchData(){
            const statisticUrl = this.$store.state.kdAPIUrl + "/api/statistic/"
            const apiArr = [
                'login/times',      // 登入次數
                'login/count',      // 登入人數
                'success/times',    // 成功詢價次數
                'success/count',    // 成功詢價人數
                'event/scene',      // 場景使用次數
                'event/material/all', // 所有材質的紀錄
                'event/download',     // 所有下載紀錄
            ].map(url => `${statisticUrl}${url}/${this.getLocale}`)

            axios.all(apiArr.map(url => axios.get(url))).then(([loginTimes, loginCount, successTimes, successCount, scene, material, download]) => {
                this.statistics.loginTimes = loginTimes.data
                this.statistics.loginCount = loginCount.data
                this.statistics.successTimes = successTimes.data
                this.statistics.successCount = successCount.data

                this.formatSceneRow(scene.data)
                this.formatMaterialRow(material.data)
                this.formatDownloadRow(download.data)
            })
        }
    },

}
</script>

<style scoped lang="scss">
    .browserTrack {
      margin-left: 1rem;
      width: 80vw;
      .lang-select-box {
        position:fixed;
        top: 10px;
        right: 2rem;
        border: rgb(125, 125, 125) 1px solid;
        border-radius: 5px;
      }
      .login-list {
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        li {
            width: 18%;
            border-radius: 5px;
            box-shadow: 0px 2px 6px #0000005e;
            padding: 20px;
            color: rgb(150, 150, 150);
            box-sizing: border-box;
            min-width: 150px;
            margin-bottom: 20px;
            span {
                font-size: 2rem;
                color: #000;
                display: block;
                margin-top: 20px;
            }
        }
      }
      .table-list {
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        font-size: 14px;
        li {
            width: 32%;
        }
        
      }
    }
</style>