<template>
  <div class="landing">
    <div class="contentFlex">
      <img v-if="!isMobileDevice && getLocale == 'zh'" src="@/assets/img/kdLogo_ZH.svg" />
      <img v-else-if="!isMobileDevice && getLocale == 'ch'" src="@/assets/img/kdLogo_CH.svg" />
      <img v-else-if="!isMobileDevice && getLocale == 'en'" src="@/assets/img/kdLogo_EN.svg" />
      <img v-else-if="isMobileDevice && getLocale == 'zh'" src="@/assets/img/kdLogoM_ZH.png" />
      <img v-else-if="isMobileDevice && getLocale == 'ch'" src="@/assets/img/kdLogoM_CH.png" />
      <img v-else-if="isMobileDevice && getLocale == 'en'" src="@/assets/img/kdLogoM_EN.png" />
      <p :class="[{ en: isEN }]">{{ $t("Title") }}</p>
      <a @click="onEnter">
        <span class="button widthNoBorder enter">{{ $t("Enter") }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import axios from "axios";

export default {
  name: "Landing",
  data() {
    return {};
  },
  components: {},
  computed: {
    isMobileDevice() {
      return this.$store.state.isMobile;
    },
    isEN() {
      return this.$i18n.locale == "en";
    },
    getLocale() {
      return this.$i18n.locale;
    }

  },
  methods: {
    onEnter() {
      const apiUrl = this.$store.state.kdAPIUrl + "/api/home";
      const data = {
        id: this.$store.state.loginData.id,
        lang: this.getLocale
      }
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios.post(apiUrl, JSON.stringify(data), config).then((response) => {
        this.$emit("enter");
      })
    },
  },
  mounted() { },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/landing";
</style>
