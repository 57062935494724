<template>
  <div class="materialList">
    <h1>{{ getTypeName }}</h1>
    <button @click="onAdd()">新增材質</button>
    <button @click="paginationOptions.setCurrentPage=1">回首頁</button>
    <vue-good-table
      :columns="tableColumns"
      :rows="materialData"
      :search-options="{
        enabled: true,
      }"
      
      :pagination-options="paginationOptions"
      @on-per-page-change="onPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <button class="editBtn" @click="onEdit(props.row)">編輯</button>
          <button v-if="props.row.publish == '0'" class="editBtn" @click="onPublish(props.row)">開放</button>
          <button v-else class="editBtn" @click="onUnpublish(props.row)">不開放</button>
          <button class="editBtn" @click="onDelete(props.row)">刪除</button>
        </span>
        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import axios from "axios";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

export default {
  name: "MaterialList",
  data() {
    return {
      type: "",
      tableColumns: [
        {
          label: "編號",
          field: "serial",
        },
        {
          label: "名稱(中)",
          field: "nameZH",
        },
        {
          label: "名稱(英)",
          field: "nameEN",
        },
        {
          label: "開放",
          field: "publish",
        },
        {
          label: "資料",
          field: "action",
        },
      ],
      materialData: [],
      paginationOptions: {
        enabled: true,
        mode: "pages",
        perPage: 10,
        setCurrentPage: 1
      },
    };
  },
  watch: {
    $route(to, from) {
      this.initData();
      
    },
  },
  components: {
    VueGoodTable,
  },
  computed: {
    getTypeName() {
      var name = "";
      switch (this.type) {
        case "cabinet": {
          name = "櫃體材質";
          break;
        }
        case "floor": {
          name = "地板材質";
          break;
        }
        case "wall": {
          name = "牆壁材質";
          break;
        }
      }
      return name;
    },
  },
  methods: {
    getBaseUrl() {
      var baseUrl = "";
      switch (this.type) {
        case "cabinet": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/cabinet/";
          break;
        }
        case "floor": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/floor/";
          break;
        }
        case "wall": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/wall/";
          break;
        }
      }
      return baseUrl;
    },
    initData() {
      
      this.type = this.$route.params["type"];
      var getDataApi = this.getBaseUrl() + "sort";
      var self = this;
      axios.get(getDataApi).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.materialData = rep.data;

        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });

    },
    onAdd() {
      var link = {
        name: "Editor",
        params: {
          type: this.type,
          action: "add",
        },
      };
      this.$router.push(link);
    },
    onEdit(data) {
      this.$store.commit("setEditMaterial", data);
      var link = {
        name: "Editor",
        params: {
          type: this.type,
          action: "edit",
          id: data.id,
        },
      };
      this.$router.push(link);
    },
    onPublish(data) {
      var getDataApi = this.getBaseUrl() + "publish/" + data.id;
      var self = this;
      axios.post(getDataApi).then((response) => {
        var rep = response.data;
        if (rep.result) {
          data.publish = "1";
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onUnpublish(data) {
      var getDataApi = this.getBaseUrl() + "unpublish/" + data.id;
      var self = this;
      axios.post(getDataApi).then((response) => {
        var rep = response.data;
        if (rep.result) {
          data.publish = "0";
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onDelete(data){
      var api = this.getBaseUrl() + data.id;
      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var self = this;
      axios.delete(api, config).then((response) => {
        var rep = response.data;
        if (rep.result) {
          alert("刪除成功");
          self.initData()
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onPageChange(params) {
      localStorage.setItem('perpage', params.currentPerPage);
    },
  },
  created() {
    var perpageTemp = localStorage.getItem('perpage');
    if (perpageTemp != null) {
      this.paginationOptions.perPage = parseInt(perpageTemp);

    }
  },
  mounted() {
    this.initData();
  
  },
};
</script>

<style scoped lang="scss">
div.materialList {
  margin-left: 1rem;
  width: 80vw;
}
</style>
