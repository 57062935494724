<template>
  <div class="orderList">
    <h1>訂單資料</h1>
    <button @click="onLoadData(0)">繁中</button>
    <button @click="onLoadData(1)">簡中</button>
    <button @click="onLoadData(2)">英文</button>
    <vue-good-table v-show="orderData.length > 0" :columns="tableColumns" :rows="orderData" :search-options="{
      enabled: true,
    }" :pagination-options="{
  enabled: true,
  mode: 'pages',
}">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <button class="editBtn" @click="onDetail(props.row)">詳細</button>
        </span>
        <span v-else-if="props.column.field == 'operator'">
          <button class="editBtn" @click="onDelete(props.row)">刪除</button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div v-if="isShowDetail" class="popoutDetail XcenterWrapper">
      <embed :src="pdfUrl" width="100%" height="100%"  />
      <p>
        <button @click="onClosePop">X</button>
        <span>{{displayDetail['orderID']}}</span>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
export default {
  name: "Order",
  data() {
    return {
      tableColumns: [
        {
          label: "單號",
          field: "orderID",
        },
        {
          label: "身份",
          field: "identity",
        },
        {
          label: "公司名稱",
          field: "company",
        },
        {
          label: "姓名",
          field: "name",
        },
        {
          label: "性別",
          field: "gender",
        },
        {
          label: "電話",
          field: "phone",
        },
        {
          label: "Email",
          field: "mail",
        },
        {
          label: "地區",
          field: "area",
        },
        {
          label: "建立日期",
          field: "created_at",
        },
        {
          label: "資料",
          field: "action",
        },
        {
          label: "操作",
          field: "operator"
        }
      ],
      orderData: [],
      isShowDetail: false,
      displayDetail: {
        id: 1,
        orderID: "1716022371646518",
        name: "name",
        phone: "phone",
        mail: "mail",
        area: "Area",
        request: null,
        cabinets: "[]",
        floors: "[]",
        walls: "[]",
        created_at: "2021-11-10T06:41:02.000000Z",
      },
      pdfUrl: null,
      fileName: 'filename'
    };
  },
  components: { VueGoodTable },
  computed: {},
  methods: {
    onLoadData(lang) {
      this.onClosePop()
      this.orderData = [];
      var getOrderApi = this.$store.state.kdAPIUrl + "/api/order/lang/" + lang;
      var self = this;
      axios.get(getOrderApi).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.orderData = rep.data.map(_data => ({
            ..._data,
            identity: _data.identity === 'designer'? '設計師': '屋主',
            gender: _data.gender === 'Mr'? '男': '女',
            created_at: new Date(_data.created_at).toLocaleString()
          }));
          rep.data, self.orderData
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
        self.isLoading = false;
      });
    },
    onDetail(data) {
      this.displayDetail = data;
      var pdfApi = this.$store.state.kdAPIUrl + "/api/pdf/create/admin";
      var jsonData = JSON.stringify({data: this.displayDetail});
      var config = {
        responseType: 'arraybuffer',
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/pdf'
        },
      };
      axios.post(pdfApi, jsonData, config).then((response) => {
          this.isShowDetail = true;
          const blob = new Blob([response.data], {type: 'application/pdf'})
          const url = window.URL.createObjectURL(blob);
          this.pdfUrl = url
      })
    },
    onDelete(data){
      const {id, orderID} = data
      const pdfApi = this.$store.state.kdAPIUrl + `/api/order/${id}`;
      axios.delete(pdfApi).then((response) => {
        if(response.status === 200){
          alert('刪除成功')
          // 直接從現有列表刪除該元素，之後如果有綁定語系，可以再重打一次語系api
          const index = this.orderData.findIndex(data => data.id === id)
          this.orderData.splice(index, 1)
        }else{
          alert(`刪除訂單 ${orderID} 失敗`)
        }
      })
    },
    onClosePop() {
      this.isShowDetail = false;
    },
  },
  mounted() {

  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/media";
@import "../../assets/scss/color";
@import "../../assets/scss/font";
@import "../../assets/scss/basic";

div.orderList {
  margin-left: 1rem;
  width: 80vw;

  div.popoutDetail {
    position: absolute;
    top: 10rem;
    width: 80%;
    height: 70%;
    overflow-y: auto;
    border: 3px solid $Yellow;
    background: white;
    margin-left: 18px;
    
    p {
      position: absolute;
      margin: 0;
      top: 60px;
      left: 0;
      display: flex;
      gap: 4px;
      align-items: flex-start;

      span{
        background-color: $Yellow;
      }
    }
  }
}
</style>
