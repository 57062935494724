<template>
    <div v-if="showCarousel" class="carousel">
        <div class="pc-carousel">
            <div class="btn-prev" @click="cutover('prev')"></div>
            <div class="pc-carousel-box">
                <ul class="pc-carousel-box" :style="`transform:translateX(${pcCarouselWidth}px);`">
                    <li v-for="(item,index) in pc" :key="index">
                        <img :src="require(`@/assets/img/carousel/pc/${isLang}/${item}.jpg`)" alt="" srcset="">
                    </li>
                </ul>
            </div>
            <div class="btn-next" @click="cutover('next')"></div> <br>
            <div v-if="isLang === 'zh'" class="btn-close zh" @click="closeCarousel()"></div>
            <div v-if="isLang === 'ch'" class="btn-close ch" @click="closeCarousel()"></div>
            <div v-if="isLang === 'en'" class="btn-close en" @click="closeCarousel()"></div>
        </div>
        <div class="mb-carousel">
            <div v-if="isLang === 'zh'" class="mb-btn-close m-zh" @click="closeCarousel()"></div>
            <div v-if="isLang === 'ch'" class="mb-btn-close m-ch" @click="closeCarousel()"></div>
            <div v-if="isLang === 'en'" class="mb-btn-close m-en" @click="closeCarousel()"></div>
            <hooper>
                <slide v-for="(item,index) in mb" :key="index">
                    <img :src="require(`@/assets/img/carousel/mb/${isLang}/${item}.jpg`)" alt="" srcset="" @click="imgClick(index)">
                </slide>
            </hooper>
        </div>
    </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
    components: {
        Hooper,
        Slide
    },
    name: "Carousel",
    data() {
        return {
            index:0,
            pcCarouselWidth:0,
            showCarousel:true,
            pc:[
                'PC_01',
                'PC_02',
                'PC_03',
                'PC_04',
                'PC_05',
                'PC_06',
                'PC_07',
            ],
            mb:[
                'Phone_01',
                'Phone_02',
                'Phone_03',
                'Phone_04',
                'Phone_05',
                'Phone_06',
                'Phone_07',
                'Phone_08',
            ],
            mbCloseImg: ''
        }
    },
    mounted() {
        const lang = this.isLang === 'zh'? 'zh': this.isLang === 'ch'? 'ch' : 'en'
        this.mbCloseImg = require(`@/assets/img/carousel/mb/${lang}/Enter_Top.png`)
    },
    computed: {
        isLang() {
            return this.$i18n.locale;
        },
    },
    methods: {
        cutover(e) {
            const boxWidth = document.querySelector('.pc-carousel-box').clientWidth
            if(e === 'next' && this.index < this.pc.length - 1) {
                this.pcCarouselWidth = this.pcCarouselWidth - boxWidth
                this.index ++
            }
            if (e === 'prev' && this.index > 0) {
                this.pcCarouselWidth = this.pcCarouselWidth + boxWidth
                this.index--
            }
        },
        closeCarousel() {
            this.showCarousel = false

        },
        imgClick(index) {
            if(index != this.mb.length - 1)
                return
            this.closeCarousel()
        }
    }

}
</script>

<style scoped lang="scss">
@import "../assets/scss/_media";
.carousel {
    position: fixed;
    top: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 80%);
    width: 100vw;
    height: 100vh;
    .pc-carousel {
        display: flex;
        padding-top: 50px;
        align-items: center;
        width: 70%;
        margin: auto;
        position: relative;
        @include sm{
            display:none;
        }
        .btn-next,.btn-prev {
            width: 40px;
            height: 100px;
            cursor: pointer;
            transition: .3s;

        }
        .btn-next {
            background: url("~@/assets/img/carousel/pc/Btn_PC_Next_Idle.png");
            &:hover {
                background: url("~@/assets/img/carousel/pc/Btn_PC_Next_Hover.png");
            }
        }
        .btn-prev {
            background: url("~@/assets/img/carousel/pc/Btn_PC_Prev_Idle.png");
            &:hover {
                background: url("~@/assets/img/carousel/pc/Btn_PC_Prev_Hover.png");
            }
        }
        .btn-close {
            width: 250px;
            height: 65px;
            position: absolute;
            bottom: - 85px;
            right: calc(50% - 125px);
            cursor: pointer;
            transition: .3s;
        }
        .zh {
            background: url("~@/assets/img/carousel/pc/zh/Enter_Idle.png");
            background-size: 100% 100%;
            &:hover {
                background: url("~@/assets/img/carousel/pc/zh/Enter_Hover.png");
            }
        }
        .ch {
            background: url("~@/assets/img/carousel/pc/ch/Enter_Idle.png");
            background-size: 100% 100%;
            &:hover {
                background: url("~@/assets/img/carousel/pc/ch/Enter_Hover.png");
                background-size: 100% 100%;
            }
        }
        .en {
            background: url("~@/assets/img/carousel/pc/en/Enter_Idle.png");
            background-size: 100% 100%;
            &:hover {
                background: url("~@/assets/img/carousel/pc/en/Enter_Hover.png");
                background-size: 100% 100%;
            }
        }
        .pc-carousel-box {
            width: 80%;
            overflow: hidden;
            margin: auto;
            transition: all .5s;
            ul{
                width: 100%;
                display: flex;
                list-style: none;
                padding: 0;
                overflow: initial;
            }
            li {
                flex-shrink:0;
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
    .mb-carousel {
        width: 100%;
        height: 100vh;
        display: none;
        @include sm {
            display: block;
        }
        .hooper {
            position: relative;
            height: 100vh;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .mb-btn-close {
            position: absolute;
            top: 2vmin;
            right: 4vmin;
            height: 7vmin;
            width: 21vmin;
            z-index: 10;
        }
        .m-zh {
            background: url("~@/assets/img/carousel/mb/zh/Enter_Top.png");
            background-size: 100% 100%;
        }
        .m-ch {
            background: url("~@/assets/img/carousel/mb/ch/Enter_Top.png");
            background-size: 100% 100%;
        }
        .m-en {
            background: url("~@/assets/img/carousel/mb/en/Enter_Top.png");
            background-size: 100% 100%;
        }
    }
}
</style>