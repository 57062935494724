import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Login from "../views/admin/Login.vue"
import AdminContainer from "../views/AdminContainer.vue";
import Orders from "../views/admin/Orders.vue";
import MaterialList from "../views/admin/MaterialList.vue";
import MaterialEdit from "../views/admin/MaterialEdit.vue";
import PropertyList from "../views/admin/PropertyList.vue";
import PropertyEdit from "../views/admin/PropertyEdit.vue";
import DefaultMaterial from "../views/admin/DefaultMaterial.vue";
import UpdateAccount from "../views/admin/UpdateAccount.vue";
import BrowserTrack from "../views/admin/BrowserTrack.vue";
import BatchUpload from "../views/admin/BatchUpload.vue";
import Sequence from "../views/admin/Sequence.vue";
import Test from "../views/Test.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Main,
  },
  {
    path:"/admin",
    component:AdminContainer,
    children: [
      {
        path:"",
        name:"Orders",
        component:Orders,
        meta: { requiresAdminAuth: true }
      },
      {
        path:"material/:type",
        name:"MaterialList",
        component:MaterialList,
        meta: { requiresAdminAuth: true }
      },
      {
        path:"material/:type/:action/:id?",
        name:"Editor",
        component:MaterialEdit,
        meta: { requiresAdminAuth: true }
      },
      {
        path:"property/:type",
        name:"PropertyList",
        component:PropertyList,
        meta: { requiresAdminAuth: true }
      },
      {
        path:"property/:type/:action/:id?",
        name:"PropertyEditor",
        component:PropertyEdit,
        meta: { requiresAdminAuth: true }
      },
      {
        path:"default/",
        name:"Default",
        component:DefaultMaterial,
        meta: { requiresAdminAuth: true }
      },
      {
        path:"change/",
        name:"Change",
        component:UpdateAccount,
        meta: { requiresAdminAuth: true }
      },
      {
        path:"Sequence/",
        name:"Sequence",
        component:Sequence,
        meta: { requiresAdminAuth: true }
      },
      {
        path:"browserTrack/",
        name:"BrowserTrack",
        component:BrowserTrack,
        meta: { requiresAdminAuth: true }
      },
      {
        path:"batchUpload/",
        name:"BatchUpload",
        component: BatchUpload,
        meta: { requiresAdminAuth: true }
      },
    ]
  },
  {
    path:"/login",
    name:"Login",
    component:Login
  }
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if ("requiresAdminAuth" in to.meta) {
    if (localStorage.getItem("isLogin") === null) {
      next('/login');
    }
    else{
      next();
    }
  }else{
    next();
  }

})

export default router;
