<template>
  <div class="itemCheckListDiv">
    <div class="header XcenterWrapperRel">
      <p>{{ $t("MyList") }}</p>
    </div>
    <div class="pageSelect XcenterWrapperRel">
      <div :class="['page', { select: pageSelect == 'cabinet' }]">
        <a @click="onSelectPage('cabinet')">
          <span
            :class="{ select: pageSelect == 'cabinet' }"
            class="pageTag"
          >{{ $t("page.Cabinet") }}</span>
        </a>
      </div>
      <div :class="['page', { select: pageSelect == 'wall' }]">
        <a @click="onSelectPage('wall')">
          <span :class="{ select: pageSelect == 'wall' }" class="pageTag">{{ $t("page.Wall") }}</span>
        </a>
      </div>
      <div :class="['page', { select: pageSelect == 'floor' }]">
        <a @click="onSelectPage('floor')">
          <span :class="{ select: pageSelect == 'floor' }" class="pageTag">{{ $t("page.Floor") }}</span>
        </a>
      </div>
    </div>
    <div class="itemListDiv XcenterWrapperRel styleScroll">
      <template v-for="(item, index) in getItemList">
        <div class="materialItem" :key="item.id">
          <ImgItem class="item" :id="index" @select="onSelect" :isSelect="item.isSelect">
            <template v-slot:img>
              <img class="photo" :src="item.url" />
            </template>
          </ImgItem>
          <p>{{ item.id }}</p>
          <p class="name">{{ item.name }}</p>
          <a v-if="getItemList.length > 1" @click="onRemove(index)">
            <img class="btnRemove" src="@/assets/img/btnRemove.png" />
          </a> 
        </div>
      </template>
    </div>
    <div class="itemListDivMobile XcenterWrapperRel">
      <carousel
        class="materialCarousel"
        :perPage="3"
        :loop="true"
        :navigationEnabled="true"
        :paginationEnabled="false"
        :navigationNextLabel="getNextButton"
        :navigationPrevLabel="getPrevButton"
        :scrollPerPage="true"
        ref="slider"
      >
        <slide v-for="(item, index) in getItemList" :key="index">
          <div v-show="!item.isEmpty" class="materialItem">
            <ImgItem class="item" @select="onSelect" :id="index" :isSelect="item.isSelect">
              <template v-slot:img>
                <img class="photo" :src="item.url" />
              </template>
            </ImgItem>
            <p>{{ item.id }}</p>
            <p class="name">{{ item.name }}</p>
            <a @click="onRemove(index)">
              <img class="btnRemove" src="@/assets/img/btnRemove.png" />
            </a>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import ImgItem from "./imgItem.vue";
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "ItemCheckList",
  props: {
  },
  data() {
    return {
      pageSelect: "cabinet",
      likeSet: {},
      selectSet: {},
      isSelect: 0 ,
    };
  },
  components: { Carousel, Slide, ImgItem },
  computed: {
    isMobileDevice() {
      return isMobile;
    },
    getItemList() {
      var itemList = this.likeSet[this.pageSelect];
      //選擇框預設
      if (itemList.length) {
        var isSelect = itemList.findIndex(v => v.isSelect)
        isSelect < 0 ? this.onSelect(0) : this.onSelect(isSelect)
      }
      if (isMobile && itemList.length > 3 && itemList.length % 3 != 0) {
        let fillNum = 3 - (itemList.length % 3);
        for (let i = 0; i < fillNum; i++) {
          let emptyItem = {
            isEmpty: true,
          };
          itemList.push(emptyItem);
        }
      }
      return itemList;
    },
    getLang() {
      return this.$i18n.locale;
    },
    getNextButton() {
      return "<img src='./assets/arrowRight.svg' class='arrow'>";
    },
    getPrevButton() {
      return "<img src='./assets/arrowLeft.svg' class='arrow'>";
    },
  },
  methods: {
    onSelectPage(val) {
      this.pageSelect = val;
      if (isMobile) {
        this.$refs["slider"].goToPage(0, "pagination");
      }
    },
    onSelect(val) {
      for (var i = 0; i < this.likeSet[this.pageSelect].length; i++) {
        this.likeSet[this.pageSelect][i].isSelect = false;
      }
      this.isSelect = val
      this.likeSet[this.pageSelect][val].isSelect =
        !this.likeSet[this.pageSelect][val].isSelect;

      this.$emit(
        "selectMaterial", {
        type: this.pageSelect,
        material: this.likeSet[this.pageSelect][val]
        }
      );
    },
    onRemove(val) {
      this.likeSet[this.pageSelect].splice(val, 1);
      this.$store.commit("removeLike", {
        type:this.pageSelect,
        index:val
      })
      this.isSelect === val && this.likeSet[this.pageSelect].length > 0 ? this.onSelect(0): null
    },
    changePage(val) {
      if (this.pageSelect != val) {
        this.pageSelect = val;
      }
    },
    getFinalList() {

      let finalSet = this.likeSet;
      var tagList = ['cabinet', 'floor', 'wall']
      for (let tagIdx = 0; tagIdx < tagList.length; tagIdx++) {
        let tag = tagList[tagIdx]

        let selectIndex = finalSet[tag].findIndex(item => item.id == this.selectSet[tag].id)
        if (selectIndex == -1) {
          finalSet[tag].push(this.selectSet[tag])
        }
      }
      return finalSet;
    },
  },
  created() { 
    this.likeSet = this.$store.getters.FinalList()
    console.log(this.likeSet);
    this.selectSet = this.$store.getters.SelectSet
  },
  mounted() {

  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/itemCheckList";
</style>
