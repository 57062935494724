<template>
  <div>
    <div class="sceneSelect styleScroll">
      <template v-for="(item, index) in sceneList">
        <div class="sceneItem" :key="item.id">
          <ImgItem
            class="scene"
            @select="onSelect"
            :id="index"
            :isSelect="item.isSelect"
          >
            <template v-slot:img>
              <img class="photo" :src="item.url" />
            </template>
          </ImgItem>
          <span class="sceneName">{{ item.name }}</span>
        </div>
      </template>
    </div>
    <div class="sceneSelectMobile">
      <p class="title">{{$t('SelectScene')}}</p>
      <div class="carousel">
        <carousel
          class="sceneCarousel"
          :perPage="1"
          :loop="true"
          :navigationEnabled="true"
          :paginationEnabled="false"
          :navigationNextLabel="getNextButton"
          :navigationPrevLabel="getPrevButton"
          :scrollPerPage="false"
          :touchDrag="false"
        >
          <slide v-for="(item, index) in sceneList" :key="index">
            <div class="sceneItem">
              <ImgItem
                class="scene"
                @select="onSelect"
                :id="index"
                :isSelect="item.isSelect"
                :key="item.id"
              >
                <template v-slot:img>
                  <img class="photo" :src="item.url" />
                </template>
              </ImgItem>
              <span class="sceneName">{{ item.name }}</span>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import ImgItem from "../components/imgItem.vue";
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "SceneSelect",
  data() {
    return {
      sceneList: [
        {
          id: 0,
          url: "/scene/Livingroom02.jpg",
          isSelect: false,
          name: this.$t('scene.LivingRoom02'),
        },
        {
          id: 1,
          url: "/scene/Livingroom01.jpg",
          isSelect: false,
          name: this.$t('scene.LivingRoom01'),
        },
        {
          id: 2,
          url: "/scene/Bedroom.jpg",
          isSelect: false,
          name: this.$t('scene.Bedroom'),
        },
        {
          id: 3,
          url: "/scene/Diningroom.jpg",
          isSelect: false,
          name: this.$t('scene.Diningroom'),
        },
        {
          id: 4,
          url: "/scene/Cloakroom.jpg",
          isSelect: false,
          name: this.$t('scene.Cloakroom'),
        },
      ],
    };
  },
  props: {
    defaultScene: {
      type: Number,
      default: 0
    },
  },
  watch: {},
  components: {
    ImgItem,
    Carousel,
    Slide,
  },
  computed: {

    getNextButton() {
      return "<img src='./assets/arrowRight.svg' class='arrow'>";
    },
    getPrevButton() {
      return "<img src='./assets/arrowLeft.svg' class='arrow'>";
    },
  },
  methods: {
    onSelect(id) {
      for (var i = 0; i < this.sceneList.length; i++) {
        this.sceneList[i].isSelect = false;
      }
      this.sceneList[id].isSelect = !this.sceneList[id].isSelect;
      this.$emit("selectScene", id);
    },
  },
  mounted() {
    this.sceneList[this.defaultScene].isSelect = true;
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/sceneSelect";
</style>

<style lang="scss">
.VueCarousel-navigation-button {
  img.arrow {
    width: 10px;
  }
}
</style>
