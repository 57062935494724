<template>
    <div class="batchUpload">
        <h1>批量上傳</h1>
        <div>
            <div class="upload-section">
                <form>
                    <label for="file" class="custom-input">
                        <input type="file" id="file" @change="handleFile" accept=".xls,.xlsx"/>
                        {{ filePrompt }}
                    </label>

                    <label for="img" class="custom-input">
                        <input type="file" id="img" @change="handleZip" accept=".zip"/>
                        {{ zipPrompt }}
                    </label>
                    
                    <button class="custom-button" type="submit" @click="onSubmit">送出</button>
                </form>
            </div>
            <div class="info">
                <button class="custom-button" @click="onDownloadXlsx">範例檔下載(.xlsx)</button>
                <button class="custom-button" @click="onDownloadZip">範例檔下載(.zip)</button>
                <p>
                    <ul>
                        <li>自動進行檔案格式檢查，全部正確才允許上傳</li>
                        <li>尋找對應資料夾內，圖片的檔名是否存在。</li>
                        <li>檢查序號是否重複</li>
                    </ul>
                </p>
            </div>
        </div>

    </div>
</template>
<script>
import axios from "axios";
export default {
    name:'BrowserTrack',
    components: {
    },
    
    data() {
        return {
            file: undefined,
            filePrompt: '選擇excel檔案',
            zip: undefined,
            zipPrompt: '選擇圖片壓縮檔案',
        }
    },

    methods: {

        // 上傳檔案變更
        handleFile(element){
            this.file = element.target.files[0];
            this.filePrompt = this.file.name;
        },

        // 上傳圖片變更
        handleZip(element){
            this.zip = element.target.files[0];
            this.zipPrompt = this.zip.name
        },

        // 提交檔案
        onSubmit(e){
            e.preventDefault();
   
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('zip', this.zip);

            const apiUrl = this.$store.state.kdAPIUrl + "/api/batch/material"

            axios.post(apiUrl, formData, config).then(({data}) => {
                const { result, ErrMsg, data: _data} = data
                if(result){
                    alert(`成功新增 ${_data} 筆資料`)
                }else{
                    alert(`新增失敗\n ${ErrMsg}`)
                }
            })
        },

        // 下載範例檔 (xlsx)
        onDownloadXlsx(){
            const apiUrl = this.$store.state.kdAPIUrl + "/api/batch/example/xlsx"
            axios.get(apiUrl, {responseType: 'blob'}).then(({data}) => {
                const url = URL.createObjectURL(new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'example.xlsx')
                document.body.appendChild(link)
                link.click()
            })
        },

        // 下載範例檔 (zip)
        onDownloadZip(){
            const apiUrl = this.$store.state.kdAPIUrl + "/api/batch/example/zip"
            axios.get(apiUrl, {responseType: 'blob'}).then(({data}) => {
                const url = URL.createObjectURL(new Blob([data], {
                    type: 'application/zip'
                }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'example.zip')
                document.body.appendChild(link)
                link.click()
            })
        },
    },

}
</script>

<style scoped lang="scss">
    .batchUpload {
      margin-left: 1rem;
      width: 80vw;

      & > div {
        display: flex;
      }
      .upload-section {
        width: 50%;
        display: flex;
      }

      .info {
        width: 50%;
        
        li {
            font-size: 16px;
        }
      }

      .custom-input, .custom-button {

        display: block;
        padding: 8px;
        border: 1px solid black;
        border-radius: 8px;
        box-shadow: 2px 2px 2px gray;
        background-color: beige;
        text-align: center;
        margin: 12px;
        cursor: pointer;

        input[type="file"]{
            display: none;
        }
      }
      
      .custom-button {
        background-color: gray;
        color: white;

      }
    }
</style>