<template>
  <div class="defaultMaterial">
    <h1>預設材質</h1>
    <div>
      <h2>客廳-1</h2>
      <label for="floor">地板</label>
      <select id="floor" v-model="defaultData[0].floor">
        <option v-for="(item, index) in floor" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">牆壁</label>
      <select id="floor" v-model="defaultData[0].wall">
        <option v-for="(item, index) in wall" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">櫃體</label>
      <select id="floor" v-model="defaultData[0].cabinet">
        <option v-for="(item, index) in cabinet" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <br />
      <button @click="onUpdate(0)">更新</button>
    </div>
    <div>
      <h2>客廳-2</h2>
      <label for="floor">地板</label>
      <select id="floor" v-model="defaultData[1].floor">
        <option v-for="(item, index) in floor" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">牆壁</label>
      <select id="floor" v-model="defaultData[1].wall">
        <option v-for="(item, index) in wall" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">櫃體</label>
      <select id="floor" v-model="defaultData[1].cabinet">
        <option v-for="(item, index) in cabinet" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <br />
      <button @click="onUpdate(1)">更新</button>
    </div>
    <div>
      <h2>臥房</h2>
      <label for="floor">地板</label>
      <select id="floor" v-model="defaultData[2].floor">
        <option v-for="(item, index) in floor" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">牆壁</label>
      <select id="floor" v-model="defaultData[2].wall">
        <option v-for="(item, index) in wall" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">櫃體</label>
      <select id="floor" v-model="defaultData[2].cabinet">
        <option v-for="(item, index) in cabinet" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <br />
      <button @click="onUpdate(2)">更新</button>
    </div>
    <div>
      <h2>餐廚</h2>
      <label for="floor">地板</label>
      <select id="floor" v-model="defaultData[3].floor">
        <option v-for="(item, index) in floor" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">牆壁</label>
      <select id="floor" v-model="defaultData[3].wall">
        <option v-for="(item, index) in wall" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">櫃體</label>
      <select id="floor" v-model="defaultData[3].cabinet">
        <option v-for="(item, index) in cabinet" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <br />
      <button @click="onUpdate(3)">更新</button>
    </div>
    <div>
      <h2>更衣室</h2>
      <label for="floor">地板</label>
      <select id="floor" v-model="defaultData[4].floor">
        <option v-for="(item, index) in floor" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">牆壁</label>
      <select id="floor" v-model="defaultData[4].wall">
        <option v-for="(item, index) in wall" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <label for="floor">櫃體</label>
      <select id="floor" v-model="defaultData[4].cabinet">
        <option v-for="(item, index) in cabinet" :value="item.id" :key="index">
          {{ item.serial }}
        </option>
      </select>
      <br />
      <button @click="onUpdate(4)">更新</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DefaultMaterial",
  data() {
    return {
      defaultData: [
        {
          floor: 0,
          cabinet: 0,
          wall: 0,
        },
        {
          floor: 0,
          cabinet: 0,
          wall: 0,
        },
        {
          floor: 0,
          cabinet: 0,
          wall: 0,
        },
        {
          floor: 0,
          cabinet: 0,
          wall: 0,
        },
        {
          floor: 0,
          cabinet: 0,
          wall: 0,
        },
      ],
      cabinet: [],
      wall: [],
      floor: [],
    };
  },
  methods: {
    GetMaterialID(list, id){
      return list.find(x=>x.id === id).materialID;
    },
    onUpdate(scene) {
      let sceneID = scene + 1;
      var postData = {
        data: {
          idFloor: this.defaultData[scene].floor,
          materialIDFloor:this.GetMaterialID(this.floor, this.defaultData[scene].floor),
          idWall: this.defaultData[scene].wall,
          materialIDWall:this.GetMaterialID(this.wall, this.defaultData[scene].wall),
          idCabinet: this.defaultData[scene].cabinet,
          materialIDCabinet:this.GetMaterialID(this.cabinet, this.defaultData[scene].cabinet),
        },
      };
      var jsonData = JSON.stringify(postData);

      var updateApi = this.$store.state.kdAPIUrl + "/api/material/" + sceneID;
      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios.post(updateApi, jsonData, config).then((response) => {
        var rep = response.data;
        if (rep.result) {
          alert("更新成功");
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    loadMaterialList() {
      var self = this;
      var getCabinet = this.$store.state.kdAPIUrl + "/api/cabinet/serial";
      axios.get(getCabinet).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.cabinet = rep.data;
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });

      var getWall = this.$store.state.kdAPIUrl + "/api/wall/serial";
      axios.get(getWall).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.wall = rep.data;
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });

      var getFloor = this.$store.state.kdAPIUrl + "/api/floor/serial";
      axios.get(getFloor).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.floor = rep.data;
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
  },
  mounted() {
    var self = this;
    this.loadMaterialList();
    var getDefault = this.$store.state.kdAPIUrl + "/api/material";
    axios.get(getDefault).then((response) => {
      var rep = response.data;
      if (rep.result) {
        for (var i = 0; i < rep.data.length; i++) {
          self.defaultData[i].floor = rep.data[i].idFloor;
          self.defaultData[i].wall = rep.data[i].idWall;
          self.defaultData[i].cabinet = rep.data[i].idCabinet;
        }
      } else {
        alert("Server Error:" + rep.ErrMsg);
      }
    });
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/media";
@import "../../assets/scss/color";
@import "../../assets/scss/font";
@import "../../assets/scss/basic";

div.defaultMaterial {
  margin-left: 1rem;
  width: 80vw;

  select {
    -webkit-appearance: auto;
    font-size: 1rem;
    margin: 0.5rem;
  }

  button {
    margin: 0.5rem 0;
  }
}
</style>
