<template>
  <div class="prppertyEdit">
    <h1 v-if="action == 'add'">新增{{ getTypeName }}</h1>
    <h1 v-else>編輯{{ getTypeName }}</h1>

    <div class="formDiv">
      <div>
        <label for="nameZH">名稱(繁中)</label>
        <input v-model="propertyData.nameZH" type="text" id="nameZH" />
      </div>
      <div>
        <label for="nameCH">名稱(簡中)</label>
        <input v-model="propertyData.nameCH" type="text" id="nameCH" />
      </div>
      <div>
        <label for="nameEN">名稱(英文)</label>
        <input v-model="propertyData.nameEN" type="text" id="nameEN" />
      </div>
      <div>
        <input type="checkbox" id="cabinet" v-model="propertyData.cabinet" />
        <label for="cabinet">櫃體</label>
        <input type="checkbox" id="floor" v-model="propertyData.floor" />
        <label for="floor">地板</label>
        <input type="checkbox" id="wall" v-model="propertyData.wall" />
        <label for="wall">牆面</label>
      </div>
      <div>{{ propertyData.id }}</div>
      <div v-if="type == 'color'">
        <Chrome v-model="colors"></Chrome>
      </div>
      <br />
      <div class="action">
        <button @click="onCancal">取消</button>
        <button v-if="action == 'add'" @click="onAdd">新增</button>
        <button v-else @click="onUpdate">更新</button>
        <button v-if="action != 'add'" @click="onDelete">刪除</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Chrome } from "vue-color";
export default {
  name: "PropertyEdit",
  data() {
    return {
      type: "",
      action: "",
      targetID: -1,
      colors: "#FFFFFF",
      propertyData: {
        id: "",
        nameZH: "",
        nameCH: "",
        nameEN: "",
        color: "",
        cabinet: 0,
        floor: 0,
        wall: 0,
      },
    };
  },
  components: {
    Chrome,
  },
  computed: {
    getTypeName() {
      var name = "";
      switch (this.type) {
        case "color": {
          name = "顏色類別";
          break;
        }
        case "product": {
          name = "產品類別";
          break;
        }
        case "wood": {
          name = "木種類別";
          break;
        }
      }
      return name;
    },
  },
  methods: {
    getBaseUrl() {
      var baseUrl = "";
      switch (this.type) {
        case "color": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/color";
          break;
        }
        case "product": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/product";
          break;
        }
        case "wood": {
          baseUrl = this.$store.state.kdAPIUrl + "/api/wood";
          break;
        }
      }
      return baseUrl;
    },
    backToList() {
      var link = {
        name: "PropertyList",
        params: {
          type: this.type,
        },
      };
      this.$router.push(link);
    },
    init(type, action) {},
    onCancal() {
      console.log("cancal");
      this.backToList();
    },
    onUpdate() {
      var updateApi = this.getBaseUrl() + "/" + this.targetID;
      this.propertyData.color = this.colors.hex;
      var postData = {
        data: {
          nameZH: this.propertyData.nameZH,
          nameCH: this.propertyData.nameCH,
          nameEN: this.propertyData.nameEN,
          color: this.propertyData.color,
          floor: this.propertyData.floor,
          cabinet: this.propertyData.cabinet,
          wall: this.propertyData.wall,
        },
      };
      var jsonData = JSON.stringify(postData);
      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var self = this;

      axios.put(updateApi, jsonData, config).then((response) => {
        var rep = response.data;
        if (rep.result) {
          alert("更新成功");
          self.backToList();
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onAdd() {
      var uploadApi = this.getBaseUrl();
      this.propertyData.color = this.colors.hex;
      var postData = {
        data: {
          nameZH: this.propertyData.nameZH,
          nameCH: this.propertyData.nameCH,
          nameEN: this.propertyData.nameEN,
          color: this.propertyData.color,
          floor: this.propertyData.floor,
          cabinet: this.propertyData.cabinet,
          wall: this.propertyData.wall,
        },
      };
      var jsonData = JSON.stringify(postData);

      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var self = this;
      axios.post(uploadApi, jsonData, config).then((response) => {
        var rep = response.data;
        if (rep.result) {
          alert("新增成功");
          self.backToList();
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
    onDelete() {
      var api = this.getBaseUrl() + "/" + this.targetID;
      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var self = this;
      axios.delete(api, config).then((response) => {
        var rep = response.data;
        if (rep.result) {
          alert("刪除成功");
          self.backToList();
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });
    },
  },
  mounted() {
    this.type = this.$route.params["type"];
    this.action = this.$route.params["action"];

    if (this.action == "edit") {
      this.targetID = this.$route.params["id"];
      this.propertyData = this.$store.state.propertyData;
      if (this.type == "color" && this.action == "edit") {
        this.colors = this.propertyData.color;
        console.log(this.colors);
      }
    }
  },
};
</script>

<style scoped lang="scss">
div.prppertyEdit {
  width: 80vw;
  margin-left: 1rem;
  overflow-y: auto;

  div.formDiv {
    div {
      margin: 1rem 0;
    }

    input {
      margin-left: 0.5rem;
    }

    select {
      -webkit-appearance: auto;
      margin-left: 0.5rem;
      font-size: 1rem;
    }
  }
}
</style>
