import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import SimpleVueValidation from "simple-vue-validator";
import VueLazyload from 'vue-lazyload' //引入這個懶載入外掛
import VueGtag from "vue-gtag";
Vue.config.productionTip = false;

Vue.prototype.Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
Vue.use(VueLazyload);

Vue.use(VueGtag, {
  config: { id: "G-KHYSHT4W9J" }
}, router);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
