<template>
  <div class="finishDiv">
    <img v-if="!isMobileDevice" src="@/assets/img/kdLogoF.png" />
    <div :class="[{ en: isEN }, 'textBox']">
      <pre :class="{ en: isEN }">{{ $t("FinishText") }}</pre>
      <div class="btnBox">
        <div v-if="isZH">
          <a  target="_blank" href="https://line.me/R/ti/p/@bax1723d"><img src="@/assets/img/finish/ZH_LINE.jpg" alt="" srcset=""></a>
          <a  target="_blank" href="https://www.facebook.com/twkeding"><img src="@/assets/img/finish/ZH_FB.jpg" alt="" srcset=""></a>
        </div>
        
        <div v-if="isCH">
          <a  target="_blank" href="https://www.douyin.com/user/MS4wLjABAAAAJEm-Tqw2NtX4-_vSIHbi5kAi38JXq0GjKJbpmrxHEH_64v1uReKoWobbD4jYNVCV" >
            <img src="@/assets/img/finish/CN_抖音.jpg" alt="" srcset="">
          </a>
          <a  target="_blank" href="https://www.cnkeding.com/data/editor/files/kdwechat.jpg" >
            <img src="@/assets/img/finish/CN_微信.jpg" alt="" srcset="">
          </a>
          <a  target="_blank" href="https://amos.alicdn.com/getcid.aw?spm=a1z10.3-b-s.0.0.605680beWZGCGh&v=3&uid=kd%BF%C6%B6%A8%C6%EC%BD%A2%B5%EA&site=cntaobao&groupid=167810093&s=1&charset=gbk&fromid=cntaobao" >
            <img src="@/assets/img/finish/CN_旺旺.jpg" alt="" srcset="">
          </a>
        </div>

        <div v-if="isEN">
          <a  target="_blank" href="http://www.instagram.com/kd_panels/?hl=zh-tw" ><img src="@/assets/img/finish/EN_IG.jpg" alt="" srcset=""></a>
          <a  target="_blank" href="https://www.facebook.com/kd.panel" ><img src="@/assets/img/finish/EN_FB.jpg" alt="" srcset=""></a>
          <a  target="_blank" href="https://linktr.ee/kdpanels" ><img src="@/assets/img/finish/EN_Linktree.jpg" alt="" srcset=""></a>
        </div>
        
      </div>
    </div>
    <div v-if="isMobileDevice" class="listPlaneDiv">
      <itemList class="itemList" :likeSet="likeSet"></itemList>
    </div>
    <div class="actionDiv">
      <button class="btn btnYellow hover-opacity" @click="onRetry">
        <span>＜ {{ $t("Retry") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import itemList from "../components/itemList.vue";
export default {
  name: "Finish",
  props: {
  },
  data() {
    return {
      likeSet: {}
    };
  },
  components: {
    itemList,
  },
  computed: {
    isEN() {
      return this.$i18n.locale == "en";
    },
    isZH() {
      return this.$i18n.locale == "zh";
    },
    isCH() {
      return this.$i18n.locale == "ch";
    },
    isMobileDevice(){
      return this.$store.state.isMobile;
    }
  },
  methods: {
    onRetry() {
      this.$emit("retry");
    }
  },
  created() {
    this.likeSet = this.$store.getters.FinalList()
  },
  mounted() { },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/media";
@import "../assets/scss/color";
@import "../assets/scss/font";
@import "../assets/scss/basic";

div.finishDiv {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include sm {
    width: 100vw;
    min-height: 100vh;
    z-index: 999;
    background-color: $WhiteGray;
    z-index: 99;
    overflow-y: auto;
  }
  img {
    width: px2rem(264);
    margin-bottom: 1rem;
  }

  div.textBox {
    border: 3px solid $Yellow;
    border-radius: 1rem;
    width: 80%;
    /* height: px2rem(160); */
    padding:px2rem(20);

    @include sm {
      width: 60%;
      /* height: px2rem(110); */
      margin-top: 2rem;
    }

    pre {
      @include font-size(24);
      font-weight: bold;
      margin: 0%;
      text-align: center;

      @include sm {
        @include font-size(20);
      }
    }

    pre.en {
      @include font-size(20);
      @include sm {
        @include font-size(18);
      }
    }
    .btnBox {
      margin-top: 20px;
      a {
        margin: auto;
        display: block;
        text-align: center;
        margin-bottom: 10px;
      }
      img {
        margin-bottom: 0;
      }
    }
  }

  div.en {
    width: 90%;
  }

  div.listPlaneDiv {
    margin-top: 1rem;
    width: 90%;
    display:none;

    @include sm{
      display:block;
    }

    div.itemList {
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }

  div.actionDiv {
    width: 100%;
    padding: 2rem 0;
    display: flex;
    justify-content: space-evenly;

    span.button {
      padding: 0.5rem 1.5rem;
    }
  }
}
</style>
