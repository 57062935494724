<template>
  <div class="contactDiv styleScroll">
    <div class="header XcenterWrapperRel">
      <p class="title">{{ $t("form.Title") }}</p>
      <span>{{ $t("form.Hint") }}</span>
      <p class="message">{{ $t("form.Msg") }}</p>
    </div>
    <div class="infoForm XcenterWrapperRel styleScroll">
      <div class="inputRadio">
        <span class="label">{{ $t("form.Identity") }}*</span>
        <label><input type="radio" v-model="contentData.identity" name="identity" value="designer"></label>{{ $t("form.Designer") }}
        <label><input type="radio" v-model="contentData.identity" name="identity" value="owner"></label> {{ $t("form.Owner") }}
        
      </div>
      <div class="inputField">
        <span class="label">{{ $t("form.company") }}</span>
        <input v-model="contentData.company"  :class="['baseInput']" type="text" />
      </div>
      <div class="inputField">
        <span class="label">{{ $t("form.Name") }}*</span>
        <input
          v-model="contentData.name"
          @change="onInputChange"
          :class="[
            { errorInput: errorList.includes('contentData.name') },
            'baseInput',
          ]"
          type="text"
        />
      </div>
      <div class="inputRadio">
        <span class="label"></span>
        <label><input type="radio"  v-model="contentData.sex" name="sex" value="Mr"></label> {{ $t("form.Mr") }}
        <label><input type="radio"  v-model="contentData.sex" name="sex" value="Miss"></label> {{ $t("form.Miss") }}
      </div>
      <div class="inputField">
        <span class="label">{{ $t("form.Phone") }}*</span>
        <input
          v-if="getLang != 'en'"
          v-model="contentData.telephone"
          @change="onInputChange"
          :class="[
            { errorInput: errorList.includes('contentData.telephone') },
            'baseInput',
          ]"
          type="text"
        />
        <vue-tel-input
          v-else
          :autoFormat="false"
          :inputOptions="inputOptions"
          :dropdownOptions="dropdownOptions"
          :allCountries="countryList"
          @input="telInputEvent"
          class="telInputDiv"
          ref="telInput"
        ></vue-tel-input>
      </div>
      <div class="inputField">
        <span v-if="getLang == 'ch'" class="label">{{ $t("form.Mail") }}</span>
        <span v-else class="label">{{ $t("form.Mail") }}*</span>
        <input
          v-model="contentData.email"
          @change="onInputChange"
          :class="[
            { errorInput: errorList.includes('contentData.email') },
            'baseInput',
            'emailField'
          ]"
          type="text"
        />
      </div>
      <div class="inputField">
        <span class="label">{{ $t("form.Area") }}*</span>
        <input
          v-model="contentData.country"
          @change="onInputChange"
          :class="[
            { errorInput: errorList.includes('contentData.country') },
            'baseInput',
          ]"
          type="text"
          :placeholder="$t('form.AreaPlaceholder')"
        />
      </div>
      <div class="textareaField">
        <span class="label">{{ $t("form.Message") }}</span>
        <textarea v-model="contentData.request" class="baseInput" type="text" />
      </div>
    </div>

    <div class="listPlaneDiv">
      <itemList :likeSet="likeSet"></itemList>
    </div>

    <div class="actionDiv">
      <button class="btn btnYellow hover-opacity" @click="onBack">
        <span>＜ {{ $t("Back") }}</span>
      </button>
      <button class="btn btnYellow hover-opacity" @click="onSubmit">
        <span>{{ $t("AskAndDownload") }} ＞</span>
      </button>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import itemList from "../components/itemList.vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import allCountries from "../assets/js/countrylist"

export default {
  name: "Contact",
  props: {

  },
  data() {
    return {
      isValidatePass: false,
      likeSet: {},
      contentData: {
        identity: "designer",
        name: "",
        company:"",
        telephone: "",
        sex: "Mr",
        email: "",
        country: "",
        request: "",
      },
      errorList: [],
      inputOptions: {
        placeholder: "",
        styleClasses: "telInput",
        autocomplete: false,
      },
      dropdownOptions: {
        showFlags: false,
        showDialCodeInList: false,
        showDialCodeInSelection: true,
        styleClasses: "telDropItem",
      },
      countryList: []
    };
  },
  validators: {
    "contentData.name": function (value) {
      return this.Validator.value(value).required();
    },
    "contentData.company": function (value) {
      return this.Validator.value(value);
    },
    "contentData.telephone": function (value) {
      if (this.$i18n.locale != "en") {
        return this.Validator.value(value).required();
      }
    },
    "contentData.email": function (value) {
      if (this.$i18n.locale == "ch") {
        return this.Validator.value(value);
      } else {
        return this.Validator.value(value).required();
      }
    },
    "contentData.country": function (value) {
      return this.Validator.value(value).required();
    },
  },
  components: {
    itemList,
    VueTelInput,
  },
  computed: {
    isMobileDevice() {
      return this.$store.state.isMobile
    },
    getLang() {
      return this.$i18n.locale;
    },
    
  },
  methods: {
    onBack() {
      this.$emit("back");
    },
    onInputChange() {
      var self = this;
      this.$validate().then(function (success) {
        if (success) {
          self.isValidatePass = true;
        } else {
          self.isValidatePass = false;
        }
      });
    },
    onSubmit() {
      this.contentData.identity = document.querySelector('input[name="identity"]:checked').value
      this.contentData.sex = document.querySelector('input[name="sex"]:checked').value
      this.$emit("submit");
    },
    telInputEvent(number, phoneObj) {

      if (phoneObj.number != undefined) {

        this.contentData["telephone"] = phoneObj.number;
      }
    },

    getContactData() {
      return this.contentData;
    },

    checkData() {
      if (this.isValidatePass) {
        return true;
      } else {
        alert("有資料尚未填寫");
        this.errorList = [];
        for (var index in this.validation.errors) {
          this.errorList.push(this.validation.errors[index].field);
        }
      }
    }
  },
  created() {
    this.likeSet = this.$store.getters.FinalList()

  },
  mounted() {
    this.countryList = allCountries;
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/contact";
</style>

<style lang="scss">
div.telInputDiv {
  ul {
    width: 250px !important;
  }
  
}
input.baseInput,.telInput {
    height: 2.2rem !important;
  }
</style>
