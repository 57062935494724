<template>
  <div class="container">
    <carousel></carousel>
    <Landing v-if="mainState == 'landing'" @enter="onLanding" class="landingView"></Landing>
    <img v-if="isMobileDevice && mainState != 'landing' && !needHideView" class="logoTop"
      src="@/assets/img/kdLogoTop.png" />
    <div class="menuActionDiv">
      <div v-if="mainState == 'landing'" class="langSelectDiv">
        <v-select v-model="langSelect" :options="options" :clearable="false" :searchable="false">
          <template class="selectedDiv" #selected-option="{ label }">
            <span class="langText">{{ label }}</span>
          </template>
          <template #option="{ label }">
            <div class="langOption">
              <i v-if="langSelect.label == label" class="fas fa-check fa-sm"></i>
              <div v-else class="baseSize"></div>
              <span>{{ label }}</span>
            </div>
          </template>
        </v-select>
      </div>

      <a :title="$t('BtnHome')" @click="toHome" v-if="mainState != 'landing'">
        <img src="@/assets/img/btnHome.png" />
      </a>
      <a :title="$t('BtnDownload')" @click="onDownload"
        v-if="mainState != 'chooseScene' && mainState != 'landing' && mainState != 'contact'">
        <img src="@/assets/img/btnDownload.png" />
      </a>
    </div>

    <div class="controlDiv">
      <div class="stepBarDiv">
        <StepBar :step="mainState" @changeStep="onChangeStep"></StepBar>
      </div>
      <div class="controlPlaneDiv">
        <SceneSelect :defaultScene="sceneSelect" @selectScene="onSelectScene" v-if="mainState == 'chooseScene'">
        </SceneSelect>
        <MaterialSelect v-if="mainState == 'chooseMetarial'" :filterList="filterList" :selectScene="sceneSelect"
          @selectMaterial="onSelectMaterial" ref="materialSelector"></MaterialSelect>
        <ItemCheckList v-if="mainState == 'checkItemList'" @selectMaterial="onSelectMaterial" ref="itemCheck">
        </ItemCheckList>
        <Contact v-if="mainState == 'contact'" @back="onBackToItemList" @submit="onSubmit" ref="contact"></Contact>
        <Finish :class="{ YcenterWrapperRel: !isMobileDevice }" @retry="onRetry" v-if="mainState == 'finish'"></Finish>
        <div v-if="mainState != 'finish'" :class="[{ hide: needHideView }, 'stepAction']">
          <button class="btn btnYellow hover-opacity" v-if="mainState == 'chooseMetarial'" @click="onBackToScene">
            <span :class="{ en: isEN }">＜ {{ $t("ReSelectScene") }}</span>
          </button>
          <button class="btn btnYellow hover-opacity" v-else-if="mainState == 'checkItemList'"
            @click="onBackToMaterial">
            <span :class="{ en: isEN }">＜ {{ $t("ModifyMaterial") }}</span>
          </button>
          <button class="btn btnYellow hover-opacity" v-else-if="mainState == 'contact'" @click="onRetry">
            <span :class="{ en: isEN }">＜ {{ $t("Retry") }}</span>
          </button>

          <button class="btn btnYellow hover-slide-down" v-if="mainState == 'chooseScene'" @click="onNextToMaterial">
            <span :class="{ en: isEN }">{{ $t("Next") }} ＞</span>
          </button>
          <button class="btn btnYellow hover-slide-down" v-else-if="mainState == 'chooseMetarial'"
            @click="onNextToItem">
            <span :class="{ en: isEN }">{{ $t("Next") }} ＞</span>
          </button>
          <button class="btn btnYellow hover-slide-down" v-else-if="mainState == 'checkItemList'"
            @click="onNextToContact">
            <span :class="{ en: isEN }">{{ $t("Next") }} ＞</span>
          </button>
          <button class="btn btnYellow hover-slide-down" v-else-if="mainState == 'contact'" @click="onSubmit">
            <span :class="{ en: isEN }">{{ $t("AskAndDownload") }} ＞</span>
          </button>
        </div>
        <a title="回到頂端" v-if="mainState == 'chooseMetarial' && !isMobileDevice" class="topBtn" @click="onToTop">
          <img src="@/assets/img/top.png" />
        </a>
      </div>
    </div>
    <div :class="[{ hide: needHideView }, 'viewerDiv']">
      <template>
        <unity v-show="isLoadFinish" v-bind="{ hideFooter: true }" src="/Build/kd.json"
          unityLoader="/Build/UnityLoader.js" externalProgress="true" ref="unityInstance"></unity>
      </template>
      <p class="photoText">{{ $t("ScenePhotoText")[sceneSelect] }}</p>
      <div v-if="!isMobileDevice && mainState == 'finish'" class="finishTextDiv centerWrapper">
        <img src="@/assets/img/btnMail.png" />
        <div>
          <span>{{ $t("DownFinishText") }}</span>
        </div>
      </div>
    </div>
    <div v-if="!isLoadFinish" class="loading">
      <div class="loadingContainer centerWrapper">
        <img class="logo" src="logo.png" />
        <div class="spinner"></div>
      </div>
    </div>
    <div v-if="isLandscape" class="rotateHints">
      <img class="hints" v-if="getLocale == 'zh'" src="@/assets/img/rotateHint.png" />
      <img class="hints" v-else-if="getLocale == 'ch'" src="@/assets/img/rotateHint_CH.png" />
      <img class="hints" v-else src="@/assets/img/rotateHint_EN.png" />

      <img class="logo" v-if="getLocale == 'zh'" src="@/assets/img/rotateLogo.png" />
      <img class="logo" v-else-if="getLocale == 'ch'" src="@/assets/img/rotateLogo_CH.png" />
      <img class="logo" v-else src="@/assets/img/rotateLogo_EN.png" />
    </div>
  </div>
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import axios from "axios";
import { isMobile } from "mobile-device-detect";
import Unity from "vue-unity-webgl";
import Landing from "../views/Landing.vue";
import StepBar from "../components/stepBar.vue";
import SceneSelect from "../components/sceneSelect.vue";
import MaterialSelect from "../components/materialSelect.vue";
import ItemCheckList from "../components/itemCheckList.vue";
import Contact from "../components/contact.vue";
import Finish from "../components/finish.vue";
import carousel from "../components/carousel.vue";
// hsuan, 20231213, 語系設定
import { LANG } from '@/config';

const LangZH = { label: "繁體中文", langTag: "zh" };
const LangCH = { label: "简体中文", langTag: "ch" };
const LangEN = { label: "English", langTag: "en" };
export default {
  name: "Main",
  data() {
    return {
      mainState: "landing",
      sceneSelect: 0,
      isLoadFinish: false,
      isLoadFull: true,
      isWaitSubmit: false,
      filterIndex2Name: {
        product: {},
        wood: {},
        color: {},
      },
      filterList: {
        cabinet: {
          product: [],
          wood: [],
          color: [],
        },
        floor: {
          product: [],
          wood: [],
          color: [],
        },
        wall: {
          product: [],
          wood: [],
          color: [],
        },
      },
      contactData: {},
      defaultData: [],
      options: [LangZH, LangCH, LangEN],
      langSelect: null,
      screenShortImg: "",
      orderID: "",
      isLandscape: false,
    };
  },

  components: {
    Unity,
    Landing,
    StepBar,
    SceneSelect,
    MaterialSelect,
    ItemCheckList,
    Contact,
    Finish,
    vSelect,
    carousel,
  },
  computed: {
    isMobileDevice() {
      return this.$store.state.isMobile;
    },
    isEN() {
      return this.$i18n.locale == "en";
    },
    getLocale() {
      return this.$i18n.locale;
    },
    needHideView() {
      return (
        this.isMobileDevice && (this.mainState === "contact" || this.mainState === "finish")
      );
    },
  },
  watch: {
    langSelect(to, from) {
      console.log('lang change')
      this.$i18n.locale = to.langTag;
      let selectLang = to.langTag;
      document.title = this.$t("PageTitle")
      var langUnity = this.getLangTagForUnity();
      this.$refs.unityInstance.message("Main", "ChangeLanguage", langUnity);
      this.$store.commit('changeLangofMaterialList', selectLang)


    },
    isMobileDevice(newValue, oldValue) {
      if (newValue) {
        this.$refs.unityInstance.message("Main", "SetDevice", "true");
      }
      else {
        this.$refs.unityInstance.message("Main", "SetDevice", "false");
      }

    }
  },
  methods: {
    getLangTagForUnity() {
      var ret = "TC";
      var lang = this.$i18n.locale;
      switch (lang) {
        case "zh": {
          ret = "TC";
          break;
        }
        case "ch": {
          ret = "SC";
          break;
        }
        case "en": {
          ret = "EN";
          break;
        }
      }
      return ret;
    },
    //Compoent Event
    onSelectScene(val) {
      if (val != this.sceneSelect) {
        this.sceneSelect = val;
        this.$refs.unityInstance.message("Main", "ChangeRoom", val);
        this.$store.commit('resetDefaultSelectAndLikeSet', val)
      }

    },

    onSelectMaterial(payload) {
      if (payload.type == "cabinet") {
        this.$refs.unityInstance.message("Main", "ChangeCabinet", payload.material.url);
      } else if (payload.type == "floor") {
        this.$refs.unityInstance.message("Main", "ChangeFloor", payload.material.url);
      } else if (payload.type == "wall") {
        this.$refs.unityInstance.message("Main", "ChangeWall", payload.material.url);
      }
      this.$store.commit('setSelectSet', { materialType: payload.type, materialData: payload.material })
    },

    toHome() {
      var lang = this.$i18n.locale;
      console.log(lang);
      var url = "https://www.twkd.com/tw/panels/product.php?act=intro";
      switch (lang) {
        case "en": {
          url = "https://www.twkd.com/en/panels/product.php?act=intro";
          break;
        }
        case "zh": {
          url = "https://www.twkd.com/tw/panels/product.php?act=intro";
          break;
        }
        case "ch": {
          // hsuan, 20230615
          url = "https://www.cnkeding.com/";
          break;
        }
      }
      window.location.href = url;
    },

    onDownload() {
      const {cabinet, wall, floor} = this.$store.state.selectSet
      const downloadUrl = this.$store.state.kdAPIUrl + '/api/event/download'
      const config = {
        headers: {
          "Content-Type": "application/json"
        },
      };
      const sendObj = {
        cabinet: cabinet.id,
        wall: wall.id,
        floor: floor.id,
        login_id: this.$store.state.loginData.id,
      }
      axios.post(downloadUrl, JSON.stringify(sendObj), config).then(({data}) => {
        console.log('log download', this.$store.state.selectSet)
      })
      this.$refs.unityInstance.message("Main", "DownloadScreenshot");
    },

    onChangeStep(step) {
      switch (step) {
        case "chooseScene": {
          this.onBackToScene()
          break;
        }
        case "chooseMetarial": {
          this.onNextToMaterial()
          break;
        }
        case "checkItemList": {
          this.onNextToItem()
          break;
        }
        case "contact": {
          this.onNextToContact()
          break;
        }
      }
    },

    //Step Event
    onLanding() {
      this.mainState = "chooseScene";
      this.sceneSelect = 0;
      this.$refs.unityInstance.message("Main", "ChangeRoom", this.sceneSelect);
      this.$store.commit('resetDefaultSelectAndLikeSet', 0)
    },
    onBackToScene() {
      this.onToScrollTop()
      this.mainState = "chooseScene";
      this.setToDefaultMaterialAndScene()
      this.$refs.unityInstance.message("Main", "HideInteractive");
    },
    onBackToMaterial() {
      this.onToScrollTop()
      this.mainState = "chooseMetarial";
    },
    onBackToItemList() {
      this.onToScrollTop()
      this.mainState = "checkItemList";
      this.$refs.unityInstance.message("Main", "ShowInteractive");
    },
    onRetry() {
      this.onToScrollTop()
      this.mainState = "chooseScene";
      this.setToDefaultMaterialAndScene()
    },
    onNextToMaterial() {
      this.onToScrollTop()
      this.mainState = "chooseMetarial";
      this.$refs.unityInstance.message("Main", "ShowInteractive");
      // 紀錄選擇的場景
      const apiUrl = this.$store.state.kdAPIUrl + '/api/event/scene'
      const config = {
        headers: {
          "Content-Type": "application/json"
        },
      };
      const mapScene = ['livingRoomA', 'livingRoomB', 'bedroom', 'diningRoom', 'closetRoom']
      const scene = this.$store.state.chooseScene
      const sendObj = {
        type: mapScene[scene], 
        login_id: this.$store.state.loginData.id,
      }
      
      axios.post(apiUrl, JSON.stringify(sendObj), config, ({data}) => {
        console.log('log scene ', data)
      })
    },
    onNextToItem() {
      this.onToScrollTop()
      this.mainState = "checkItemList";
      let likeSet = this.$refs.materialSelector.getLikeSet();
      this.$store.commit('updateLikeSet', likeSet)
    },
    onNextToContact() {
      this.onToScrollTop()
      const likeList = this.$store.state.likeList
      const mapArr = []
      // 整理加入到最愛的材質
      for (const key in likeList) {
        const arr = likeList[key]
        mapArr.push(...arr.map(obj => ({
          type: key, 
          serial: obj.id, 
          add: 1, 
          login_id: this.$store.state.loginData.id,
        })))
      }

      // 紀錄加到最愛的材質
      const logApiUrl = this.$store.state.kdAPIUrl + "/api/event/material"
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      axios.all(mapArr.map(data => axios.post(logApiUrl, JSON.stringify(data), config))).then(res => {
        console.log('log all favorite done', res)
      })
      
      this.mainState = "contact";
      this.$refs.unityInstance.message("Main", "HideInteractive");
      this.$refs.unityInstance.message("Main", "GetScreenshot");
    },
    onToTop() {
      this.$refs.materialSelector.scrollToTop();
     
    },
    onToScrollTop(){
      document.documentElement.scrollTop = 0
    },

    onSubmit() {
      this.onToScrollTop()
      if (this.isWaitSubmit) {
        return;
      }
      if (this.$refs.contact.checkData()) {
        this.contactData = this.$refs.contact.getContactData();
        this.submitToServer();
      }
    },
    submitToServer() {
      const uploadApi = this.$store.state.kdAPIUrl + "/api/order";
      const langType = this.$i18n.locale === 'ch'? 1: this.$i18n.locale === 'en'? 2: 0;
      const postData = {
          identity: this.contactData.identity,
          company: this.contactData.company,
          name: this.contactData.name,
          phone: this.contactData.telephone == '' ? "unknown" : this.contactData.telephone,
          gender: this.contactData.sex,
          mail: this.contactData.email,
          area: this.contactData.country,
          request: this.contactData.request,
          langType,
          cabinets: this.materialTransformToServer("cabinet"),
          floors: this.materialTransformToServer("floor"),
          walls: this.materialTransformToServer("wall"),
          image: this.screenShortImg
      };

      const jsonData = JSON.stringify(postData);
      const config = {
        headers: {
          "Content-Type": "application/json"
        },
      };
      const self = this

      axios.post(uploadApi, jsonData, config).then((response) => {

        const {result, data, ErrMsg} = response.data;
        if (result) {
          console.log("Upload Order Success :" + data);
          self.orderID = data;
          self.createPDF();
          const logSuccessApi = this.$store.state.kdAPIUrl + "/api/success";
          // 紀錄成功完成詢價
          axios.post(logSuccessApi, JSON.stringify({id: this.$store.state.loginData.id}), config).then((response) => {
            console.log('紀錄成功詢價', response)
          })
        } else {
          alert("Server Error:" + ErrMsg);
        }
      }).catch(err => {
        if(/422/.test(err.message)) {
          alert('Server Error: ' + '資料格式錯誤')
        }else{
          alert("Server Error:" + err.message);
        }
      }) 
    },
    materialTransform(type) {
      var data = this.$store.getters.FinalList()[type];
      var result = [];
      for (var i = 0; i < data.length; i++) {

        let item = {
          code: data[i].id,
          name: data[i].name,
          notes: [],
          selected: data[i].isSelect
        };

        for (var j = 0; j < data[i].similarItem.length; j++) {
          if (data[i].similarItem[j] != '') {
            item.notes.push(data[i].similarItem[j])
          }

        }
        result.push(item);
      }
      return result;
    },
    materialTransformToServer(type) {
      var data = this.$store.getters.FinalList()[type];
      var result = [];
      for (var i = 0; i < data.length; i++) {
        let item = data[i].id.toString() + "-" + data[i].name.toString();
        if (data[i].similarItem.length > 0) {
          if (data[i].similarItem[0] != '') {
            item += " " + this.$t('Similar') + ":";
            for (var j = 0; j < data[i].similarItem.length; j++) {
              item += data[i].similarItem[j] + ","
            }
          }
        }

        result.push(item);
      }
      return JSON.stringify(result);
    },
    materialDataTrasnform(type, data) {
      let materialListTemp = []
      var baseUrl = this.$store.state.kdAPIUrl + "/storage/" + type + "/";
      for (var i = 0; i < data.length; i++) {
        let similarList = [];
        let name = "";
        if (this.$i18n.locale == "zh") {
          name = data[i].nameZH;
          if (data[i].similarListZH.length > 0) {
            similarList = data[i].similarListZH.split("\n");
          }
        } else if (this.$i18n.locale == "ch") {
          name = data[i].nameCH;
          if (data[i].similarListCH.length > 0) {
            similarList = data[i].similarListCH.split("\n");
          }
        } else {
          name = data[i].nameEN;
          if (data[i].similarListEN.length > 0) {
            similarList = data[i].similarListEN.split("\n");
          }
        }

        let item = {
          dbid: data[i].id,
          id: data[i].serial,
          url: baseUrl + data[i].materialID + ".jpg",
          name: name,
          nameZH: data[i].nameZH,
          nameCH: data[i].nameCH,
          nameEN: data[i].nameEN,
          similarItem: similarList,
          similarItemZH: data[i].similarListZH.split("\n"),
          similarItemCH: data[i].similarListCH.split("\n"),
          similarItemEN: data[i].similarListEN.split("\n"),
          isSelect: false,
          isLike: false,
          type: data[i].type,
          woodType: data[i].woodType,
          color: data[i].colorType,
        };

        //this.materialList[type].push(item);
        materialListTemp.push(item)
      }
      this.$store.commit('initMaterialList', {
        materialType: type,
        data: materialListTemp
      })

      this.$store.commit('setDefaultMaterialOrder', type)
    },
    loadMaterialFromDB() {
      var self = this;
      var getCabinet = this.$store.state.kdAPIUrl + "/api/cabinet/sort";
      axios.get(getCabinet).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.materialDataTrasnform("cabinet", rep.data);
          console.log("Load cabinet Finish")
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });

      var getWall = this.$store.state.kdAPIUrl + "/api/wall/sort";
      axios.get(getWall).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.materialDataTrasnform("wall", rep.data);
          console.log("Load wall Finish")
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });

      var getFloor = this.$store.state.kdAPIUrl + "/api/floor/sort";
      axios.get(getFloor).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.materialDataTrasnform("floor", rep.data);
          console.log("Load Floor Finish")
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });

    },
    filterDataTransform(pageType, filterType, data) {
      for (var i = 0; i < data.length; i++) {
        let item = {
          id: data[i].id,
          name: data[i].nameZH,
          nameEN: data[i].nameEN,
          nameCH: data[i].nameCH,
        };
        this.filterList[pageType][filterType].push(item);
        this.filterIndex2Name[filterType][data[i].id] = item;
      }
    },
    loadFromDB() {
      var self = this;
      var getProperty = this.$store.state.kdAPIUrl + "/api/property";
      axios.get(getProperty).then((response) => {
        var rep = response.data;
        if (rep.result) {
          self.filterDataTransform("cabinet", "wood", rep.woodForCabinet);
          self.filterDataTransform("floor", "wood", rep.woodForFloor);
          self.filterDataTransform("wall", "wood", rep.woodForWall);

          self.filterDataTransform("cabinet", "product", rep.productForCabinet);
          self.filterDataTransform("floor", "product", rep.productForFloor);
          self.filterDataTransform("wall", "product", rep.productForWall);

          self.filterDataTransform("cabinet", "color", rep.colorForCabinet);
          self.filterDataTransform("floor", "color", rep.colorForFloor);
          self.filterDataTransform("wall", "color", rep.colorForWall);
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });

      var getDefault = this.$store.state.kdAPIUrl + "/api/material";
      axios.get(getDefault).then((response) => {
        var rep = response.data;
        if (rep.result) {


          self.$store.commit('setDefaultMaterial', rep.data)
          self.loadMaterialFromDB()
        } else {
          alert("Server Error:" + rep.ErrMsg);
        }
      });


    },
    login(){
      const apiUrl = this.$store.state.kdAPIUrl + "/api/home";

      axios.get(apiUrl).then(({data}) => {
        // 放入全域中
        this.$store.commit('setLoginData', data)
      })
    },
    getName(pageType, filterType, index) {
      let name = "";
      switch (this.$i18n.locale) {
        case "zh": {
          name = this.filterIndex2Name[filterType][index].name;
          break;
        }
        case "ch": {
          name = this.filterIndex2Name[filterType][index].nameCH;
          break;
        }
        case "en": {
          name = this.filterIndex2Name[filterType][index].nameEN;
          break;
        }
      }
      return name;
    },
    createPDF() {
      const langUnity = this.getLangTagForUnity();
      const postData = {
        data: {
          language: langUnity,
          orderNumber: this.orderID,
          contactInfo: this.contactData,
          inquiryList: {
            cabinets: this.materialTransform("cabinet"),
            walls: this.materialTransform("wall"),
            flooring: this.materialTransform("floor"),
          },
          image: this.screenShortImg,
        }
      };
      const jsonData = JSON.stringify(postData);
      const config = {
        responseType: 'arraybuffer',
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/pdf'
        },
      };

      const self = this;
      const pdfApi = this.$store.state.kdAPIUrl + "/api/pdf/create";
      axios.post(pdfApi, jsonData, config).then((response) => {
        self.mainState = "finish";
        self.isWaitSubmit = false;
        self.isLoadFinish = true;

        setTimeout(() => { 
          // 同時下載的話會造成讀圖錯誤
          const pdfName = self.$t("PDFName") + self.orderID.toString() + '.pdf'
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', pdfName); //or any other extension
          document.body.appendChild(link);
          link.click();
         }, 3000)
      });
    },
    setToDefaultMaterialAndScene() {
      this.sceneSelect = 0
      let defaultMaterial = this.$store.getters.SceneDefaultMaterial(0)
      this.$store.commit('resetDefaultSelectAndLikeSet', 0)

      this.$refs.unityInstance.message("Main", "ChangeRoom", 0);
      this.$refs.unityInstance.message("Main", "Reset");
    },
    onResize() {
      if(window.innerWidth > 820){    // pc or ipad 橫板
        var fontSize = window.innerWidth * 0.009375;
        document.documentElement.style.fontSize = fontSize + "px";
        this.$store.commit("setIsMobile", false)
      }else {
        document.documentElement.style.fontSize = "16px";
        this.$store.commit("setIsMobile", true)
      }
    },
    onOrientationChange() {
      if (isMobile && window.innerWidth < 820 && (window.orientation == 90 || window.orientation == -90)) {
        this.isLandscape = true;
      } else {
        this.isLandscape = false;
      }
    }
  },
  created() {
    var self = this;
    window.addEventListener("resize", this.onResize);
    window.addEventListener("orientationchange", this.onOrientationChange);
    window["UnityButtonEvent"] = (msg) => {
      if (self.mainState == "chooseMetarial") {
        self.$refs.materialSelector.changePage(msg);
      } else if (self.mainState == "checkItemList") {
        self.$refs.itemCheck.changePage(msg);
      }
    };
    window["OnScreenshot"] = (image) => {
      console.log("OnScreenshot");
      self.screenShortImg = image;
    };
    window["OnInitFinish"] = () => {
      self.isLoadFinish = true;
    };
    window["UnityProgress"] = (gameInstance, progress) => {
      if (!gameInstance.Module) {
        return;
      }

      if (progress === 1 && !gameInstance.removeTimeout) {
        if (isMobile) {
          console.log("Set to mobile");
          self.$refs.unityInstance.message("Main", "SetDevice", "true");
        }

        let defaultData = this.$store.getters.DefaultMaterial
        if (defaultData != []) {
          var defaultMaterial = {
            Texture: [],
          };


          var floorUrl = this.$store.state.kdAPIUrl + "/storage/floor/";
          var wallUrl = this.$store.state.kdAPIUrl + "/storage/wall/";
          var cabinetUrl = this.$store.state.kdAPIUrl + "/storage/cabinet/";
          for (var i = 0; i < defaultData?.length; i++) {
            let data = {
              Floor: floorUrl + defaultData[i].materialIDFloor + ".jpg",
              Wall: wallUrl + defaultData[i].materialIDWall + ".jpg",
              Cabinet:cabinetUrl + defaultData[i].materialIDCabinet + ".jpg",
            };
            defaultMaterial.Texture.push(data);
          }
          var defaultMaterialStr = JSON.stringify(defaultMaterial);
          console.log(defaultMaterialStr);
          self.$refs.unityInstance.message(
            "Main",
            "InitScene",
            defaultMaterialStr
          );
        }

        var langUnity = this.getLangTagForUnity();
        this.$refs.unityInstance.message("Main", "ChangeLanguage", langUnity);

      }
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    document.title = this.$t("PageTitle")
    this.loadFromDB();
    this.login()
    // hsuan, 20231213, 語系設定
    var userLang = LANG;
    userLang = userLang.toLowerCase();
    if (userLang.indexOf("tw") != -1) {
      this.langSelect = LangZH;
    } else if (userLang.indexOf("cn") != -1) {
      this.langSelect = LangCH;
    } else {
      this.langSelect = LangEN;
    }

    this.onResize()
    this.onOrientationChange()
  },
};
</script>

<style lang="scss">
@import "../assets/scss/main";

#vs1__combobox {
  border: none !important;
}

// div.vs__actions {
//   display: none !important;
// }

.vs__search {
  padding: 0;
}

.vs__select {
  padding: 0;
  border: none;
}

.vs__selected-options {
  min-width: 4.4rem;
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;

  @include sm {
    //width: 6rem;
    @include font-size(18);
  }
}

div.langOption {
  display: flex;
  align-items: center;
  width: 6.6rem;

  div.baseSize {
    display: block;
    //width: 0.875em;
  }

  span {
    font-size: 0.8125rem;
    font-weight: bold;

    @include sm {
      @include font-size(16);
    }
  }
}

#vs1__listbox {
  li {
    background-color: rgba(0, 0, 0, 0);
    color: $Black;
  }
}

img.logoTop {
  position: absolute;
  width: 120px;
  left: 2rem;
  top: 1rem;
  z-index: 99;
}
</style>
