<template>
  <div class="login">
    <div class="loginDiv centerWrapper">
      <h1>產品選材系統-後台</h1>
      <div>
        <label for="account">帳號</label>
        <input v-model="account" type="text" id="account" />
      </div>
      <div>
        <label for="password">密碼</label>
        <input v-model="password" type="password" id="password" />
      </div>
      <button @click="OnLogin">登入</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      account: "",
      password: "",
    };
  },
  components: {},
  computed: {},
  methods: {
    OnLogin() {
      var postData = {
        data: {
          account: this.account,
          password: this.password,
        },
      };
      var jsonData = JSON.stringify(postData);

      // console.log(this.$store);
      
      var loginApi = this.$store.state.kdAPIUrl + "/api/admin/login";

      var config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var self = this;
      axios.post(loginApi, jsonData, config).then((response) => {
        var rep = response.data;
        // hsuan, 20230908, 修正外包很扯錯誤,帳號密碼隨便打也可以登入?? 我也是滿頭問號
        if (rep.result === true) {
          localStorage.setItem("isLogin", self.account);
          alert("登入成功");
          self.$router.push({ name: "Orders" });
        } else {
          alert("登入失敗 Server Error:" + rep.ErrMsg);
        }
      });
    },
  },
  mounted() {
    if (localStorage.getItem("isLogin") !== null) {
      this.$router.push({ name: "Orders" });
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/media";
@import "../../assets/scss/color";
@import "../../assets/scss/font";
@import "../../assets/scss/basic";
div.login {
  width: 100%;
  height: 100vh;
  background-color: $Yellow;
  div.loginDiv {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      margin: 1rem;
      font-size: 1rem;
    }

    button {
      font-size: 1rem;
    }
  }
}
</style>
