<template>
    <div class="Sequence">
        <h1>自訂排序</h1>
        <div class="menu-box">
            <ul>
                <li :class="type === 'product' && 'active'" @click="getList('product')">產品類別</li>
                <li :class="type === 'wood' && 'active'" @click="getList('wood')">木種類別</li>
                <li :class="type === 'floor' && 'active'" @click="getList('floor')">地板材質</li>
                <li :class="type === 'cabinet' && 'active'" @click="getList('cabinet')">櫃體材質</li>
                <li :class="type === 'wall' && 'active'" @click="getList('wall')">牆壁材質</li>
            </ul>
            <ul>
                <li @click="onConfirm">確認送出</li>
            </ul>
        </div>
        <div class="item-box">
            <ul>
            <draggable
                :list="list"
                :disabled="false"
                class="list-group"
                ghost-class="ghost"
                @start="dragging = true"
                @end="dragging = false"
            >
                <li v-for="item in list" :key="item.id" class="material-box" :class="{ 'close-img': closeImg }">
                    <template v-if="!closeImg">
                        <img class="material-img" :src="item.img" alt="" srcset=""> 
                        <br> {{ item.serial }}        
                        <br> {{ item.nameZH }}    
                        <br> {{ item.nameEN }}    
                    </template>
                    <template v-else>
                        {{ item.nameZH }}
                        <br> {{ item.nameEN }}
                    </template>
                </li>
            </draggable>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import draggable from 'vuedraggable'

export default {
    name:'Sequence',

    components: {
        draggable,
    },

    data() {
        return{
            closeImg: true,
            list:[],
            type: null,
            Product: [],
            Wood: [],
            Floor:[],
            Wall:[],
            Cabinet:[],
        }
    },
    computed: {
    },
    created() {

        var productUrl = this.$store.state.kdAPIUrl + "/api/product/sort"
        axios.get(productUrl).then((data) => {
            this.Product = data.data.data
        })
        var woodUrl = this.$store.state.kdAPIUrl + "/api/wood/sort"
        axios.get(woodUrl).then((data) => {
            this.Wood = data.data.data
        })
        var floorUrl = this.$store.state.kdAPIUrl + "/api/floor/sort"
        axios.get(floorUrl).then((data) => {
            this.Floor = data.data.data
            this.Floor.map((v,i) =>{
                let img = this.$store.state.kdAPIUrl + "/storage/floor/" + v.materialID +".jpg"
                this.Floor[i].img = img
            })
        })
        var wallUrl = this.$store.state.kdAPIUrl + "/api/wall/sort"
        axios.get(wallUrl).then((data) => {
            this.Wall = data.data.data
            this.Wall.map((v, i) => {
                let img = this.$store.state.kdAPIUrl + "/storage/wall/" + v.materialID + ".jpg"
                this.Wall[i].img = img
            })
        })
        var cabinetUrl = this.$store.state.kdAPIUrl + "/api/cabinet/sort"
        axios.get(cabinetUrl).then((data) => {
            this.Cabinet = data.data.data
            this.Cabinet.map((v, i) => {
                let img = this.$store.state.kdAPIUrl + "/storage/cabinet/" + v.materialID + ".jpg"
                this.Cabinet[i].img = img
            })
        })

    },
    methods: {
        getList(type) {
            switch (type) {
                case "product": {
                    this.closeImg = true
                    this.list = this.Product
                    break;
                }
                case "wood": {
                    this.closeImg = true
                    this.list = this.Wood
                    break;
                }
                case "cabinet": {
                    this.closeImg = false
                    this.list = this.Cabinet
                    break;
                }
                case "floor": {
                    this.closeImg = false
                    this.list = this.Floor;
                    break;
                }
                case "wall": {
                    this.closeImg = false
                    this.list = this.Wall;
                    break;
                }

            }
            this.type = type
        },

        onConfirm() {
            const apiUrl = this.$store.state.kdAPIUrl + `/api/${this.type}/sort`
            console.log(this.list, this.type, apiUrl)
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            const data = {
                idArr: this.list.map(({id}) => id)
            }
            axios.post(apiUrl, JSON.stringify(data), config).then(({data}) => {
                const {result} = data
                if(result){
                    alert('排序成功')
                }else{
                    alert('排序失敗')
                }
            })
        }

        
    }
}

</script>

<style scoped lang="scss">
.Sequence {
    margin-left: 1rem;
    width: 80vw;
    ul,li {
        padding: 0;
        list-style: none;
    }
    .menu-box {
        display: flex;
        justify-content: space-between;

        ul {
            padding: 0;
            display: flex;
            li{
                border: 1px solid black;
                border-radius: 8px;
                padding: 8px;
                background-color: beige;
                list-style: none;
                margin-right: 15px;
                font-size: 18px;
                cursor: pointer;

                &.active{
                    box-shadow: 0 4px 2px gray;
                }
            }
        }
    }
    .item-box {
        ul > div{            
            display: flex;
            flex-wrap: wrap;
        }
        .material-box {
            margin-right: 10px;
            margin-bottom: 20px;
            text-align: center;
            font-size: 14px;
            .material-img {
                width: 160px;
                height: 160px;
                overflow: hidden;
                border-radius:10px;
            }

        }
        .close-img {
            padding: 10px 20px;
            border: 1px solid rgb(56, 56, 56);
            border-radius: 10px;
        }
    }


    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
}
</style>